<div class="container">
  <div class="row">
    <div class="login-container">
      <h2>Login</h2>
      <form (ngSubmit)="onLogin()">
        <div class="form-group">
          <label for="username">Username</label>
          <input type="text" id="username" name="username" [(ngModel)]="username" required>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" id="password" name="password" [(ngModel)]="password" required>
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  </div>
</div>
