import {Component} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AutoScraperBackendService} from "../auto-scraper-backend.service";
import {SupplierImplementation} from "../model";
import {catchError} from "rxjs/operators";
import {EMPTY} from "rxjs";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  constructor(private http: HttpClient, public data: AutoScraperBackendService) {
  }

  searchQuery: string = '';
  searchResults: any[] = [];

  search() {
    const headers = new HttpHeaders({
      'auth': this.data.token
    });

    let input = {"identifier": this.searchQuery}
    if (this.searchQuery) {
      this.http.post(environment.apiUrl + "/search_supplier", input, {headers}).
      subscribe((data: any) => {
        this.searchResults = data.results;
      });
    }
  }

  create_or_load_implementation(id: string) {
    // this is the new way
    let input = {"identifier": id}
    let status = document.getElementById("load_status")
    const headers = new HttpHeaders({
      'auth': this.data.token
    });
    this.http.post(environment.apiUrl + "/create_or_load_implementation", input, {headers}).pipe(
      catchError((error) => {
        // @ts-ignore
        status.innerText = "Error"
        return EMPTY
      })
    ).subscribe((res) => {
      this.data.supplier_implementation = res as SupplierImplementation

      if (this.data.supplier_implementation.direct_hits_selectors.condition == null) {
        this.data.supplier_implementation.direct_hits_selectors.condition = []
      }
      // @ts-ignore
      status.innerText = "Successfully created/loaded supplier " + this.data.supplier_implementation.basic_supplier_info.name
    });
  }
}
