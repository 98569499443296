<div class="container">
  <div class="row">
    <h2>No Hits</h2>
    <br><br>
  </div>

  <br>

  <div class="row">
    <div style="display: flex;">
      <div style="width: 50%; background-color: lightblue;">
        <b>No Hits 1</b>

        <div class="row">
          <div class="col-1"></div>

        </div>

        <div class="row">
          <form #addPastedPageSource_0="ngForm" (ngSubmit)="add_pasted_page_source(0, addPastedPageSource_0.value)">
            Paste page source:
            <input type="text" name="pasted_source" [(ngModel)]="this.data.supplier_implementation.no_hits[0].page_source" >
            <button class="btn btn-secondary">Add page source</button>
            <b id="source_status2_nh_0"></b>
          </form>
        </div>

      </div>
      <div style="width: 50%; background-color: lightpink;">
        <b>No Hits 2</b>

        <div class="row">
          <div class="col-1"></div>

        </div>

        <div class="row">
          <form #addPastedPageSource_1="ngForm" (ngSubmit)="add_pasted_page_source(1, addPastedPageSource_1.value)">
            Paste page source:
            <input type="text" name="pasted_source" [(ngModel)]="this.data.supplier_implementation.no_hits[1].page_source"
                   ngModel>
            <button class="btn btn-secondary">Add page source</button>
            <b id="source_status2_nh_1"></b>
          </form>
        </div>


      </div>
    </div>
  </div>


  <br><br>

</div>

