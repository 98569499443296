<div class="container">
  <p>
    <button (click)="do_classification_training()" class="btn btn-secondary">Train Classifier</button>
    {{status_message}}
  </p>

</div>


<div class="container">
  <div class="col-12"><h3>Results:</h3></div>
  <div class="col-12">Method of
    classification: {{this.data.supplier_implementation.page_type_classification.method_of_page_type_classification}}</div>
</div>
