import {Component} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AutoScraperBackendService} from '../auto-scraper-backend.service';
import {FormControl, FormGroup} from '@angular/forms';
import {PageResult, PageSource, RankedDirectHitSelectors} from "../model";
import {catchError} from "rxjs/operators";
import {EMPTY} from "rxjs";
import {environment} from "../../environments/environment";


@Component({
  selector: 'app-direct-hit',
  templateUrl: './direct-hit.component.html',
  styleUrls: ['./direct-hit.component.css']
})
export class DirectHitComponent {
  constructor(private http: HttpClient, public data: AutoScraperBackendService) {
  }

  schema_status: string[] = ["", ""]
  oem_brand_status: string = ""
  oem_number_status: string = ""
  oem_gtin_status: string = ""
  internal_name_status: string = ""
  internal_number_status: string = ""
  internal_desc_status: string = ""
  internal_group_status: string = ""
  price_amount_status: string = ""
  price_price_status: string = ""
  price_delivery_text_status: string = ""
  condition_status: string = ""

  parsed_price_1: string = ""
  parsed_price_2: string = ""


  get_page_source_from_url(idx: number, url: any) {
    const source_status = document.getElementById("source_status_dh_" + idx)

    // @ts-ignore
    source_status.innerHTML = ""

    let the_url = {"url": url.url}
    console.log(the_url)

    try {
      new URL(<string><unknown>url.url);
    } catch (_) {
      // @ts-ignore
      source_status.innerHTML = "No valid URL provided"
      return;
    }

    // @ts-ignore
    source_status.innerHTML = "Please wait"

    const headers = new HttpHeaders({
      'auth': this.data.token
    });
    this.http.post(environment.apiUrl + "/get_page_source", the_url, {headers}).pipe(
      catchError((error) => {
        // @ts-ignore
        source_status.innerText = "Error"
        return EMPTY
      })
    )
      .subscribe((res) => {
        if (typeof (res) === "string") {
          // @ts-ignore
          source_status.innerHTML = res
          return
        }
        const page_source = res as PageSource
        this.data.supplier_implementation.direct_hits[idx].page_source = page_source.page_source
        this.data.supplier_implementation.direct_hits[idx].url = url.url
        // @ts-ignore
        source_status.innerHTML = "OK"
      });
  }

  add_pasted_page_source(idx: number, page_source: any) {
    this.data.supplier_implementation.direct_hits[idx].page_source = page_source.pasted_source
    const source_status = document.getElementById("source_status2_dh_" + idx)
    // @ts-ignore
    source_status.innerHTML = "OK"
  }

  get_schema_values(idx: number) {
    this.schema_status[idx] = "Working on it."
    let page_source = {"page_source": this.data.supplier_implementation.direct_hits[idx].page_source}

    const headers = new HttpHeaders({
      'auth': this.data.token
    });

    this.http.post(environment.apiUrl + "/get_schema", page_source, {headers}).pipe(
      catchError((error) => {
        // @ts-ignore
        source_status.innerText = "Error"
        return EMPTY
      })
    )
      .subscribe((res) => {
        if (typeof res === "string") {
          this.schema_status[idx] = res
          return
        }

        const schema_response = res as PageResult

        this.data.supplier_implementation.direct_hits[idx].target_values.oem_info.brand = schema_response.oem_info.brand
        this.data.supplier_implementation.direct_hits[idx].target_values.oem_info.number = schema_response.oem_info.number
        this.data.supplier_implementation.direct_hits[idx].target_values.oem_info.gtin = schema_response.oem_info.gtin
        this.data.supplier_implementation.direct_hits[idx].target_values.internal_info.number = schema_response.internal_info.number
        this.data.supplier_implementation.direct_hits[idx].target_values.internal_info.name = schema_response.internal_info.name
        this.data.supplier_implementation.direct_hits[idx].target_values.internal_info.description = schema_response.internal_info.description
        this.data.supplier_implementation.direct_hits[idx].target_values.internal_info.group = schema_response.internal_info.group
        this.data.supplier_implementation.direct_hits[idx].target_values.prices.amount = schema_response.prices.amount
        this.data.supplier_implementation.direct_hits[idx].target_values.prices.price = schema_response.prices.price
        this.data.supplier_implementation.direct_hits[idx].target_values.prices.delivery_text = schema_response.prices.delivery_text
        this.data.supplier_implementation.direct_hits[idx].target_values.prices.delivery = schema_response.prices.delivery

        this.data.supplier_implementation.direct_hits[idx].page_result.oem_info.brand = schema_response.oem_info.brand
        this.data.supplier_implementation.direct_hits[idx].page_result.oem_info.number = schema_response.oem_info.number
        this.data.supplier_implementation.direct_hits[idx].page_result.oem_info.gtin = schema_response.oem_info.gtin
        this.data.supplier_implementation.direct_hits[idx].page_result.internal_info.number = schema_response.internal_info.number
        this.data.supplier_implementation.direct_hits[idx].page_result.internal_info.name = schema_response.internal_info.name
        this.data.supplier_implementation.direct_hits[idx].page_result.internal_info.description = schema_response.internal_info.description
        this.data.supplier_implementation.direct_hits[idx].page_result.internal_info.group = schema_response.internal_info.group
        this.data.supplier_implementation.direct_hits[idx].page_result.prices.amount = schema_response.prices.amount
        this.data.supplier_implementation.direct_hits[idx].page_result.prices.price = schema_response.prices.price
        this.data.supplier_implementation.direct_hits[idx].page_result.prices.delivery_text = schema_response.prices.delivery_text
        this.data.supplier_implementation.direct_hits[idx].page_result.prices.delivery = schema_response.prices.delivery

        this.schema_status[idx] = "Found schema."
      });
  }

  find_selectors(target_value_1: string, target_value_2: string, fast: boolean) {
    const headers = new HttpHeaders({
      'auth': this.data.token
    });

    const input = {
      "search_items":
        [{
          "page_source": this.data.supplier_implementation.direct_hits[0].page_source,
          "target_value": target_value_1
        }, {
          "page_source": this.data.supplier_implementation.direct_hits[1].page_source,
          "target_value": target_value_2,
        }]
    }
    if (fast) {
      return this.http.post(environment.apiUrl + "/search_identifier_for_given_values_fast", input, {headers})
    }
    return this.http.post(environment.apiUrl + "/search_identifier_for_given_values", input, {headers})
  }


  are_values_ok(target_value0: string, target_value1: string): boolean {
    return !(target_value0 === "" || target_value0 == null || target_value1 === "" || target_value1 == null);
  }

  onSubmit_oem_brand(fast: boolean) {
    if (<string>this.oem_brand.value.input0 === '') {
      this.oem_brand.value.input0 = this.data.supplier_implementation.direct_hits[0].target_values.oem_info.brand
    }

    if (<string>this.oem_brand.value.input1 === '') {
      this.oem_brand.value.input1 = this.data.supplier_implementation.direct_hits[1].target_values.oem_info.brand
    }

    if (!this.are_values_ok(<string>this.oem_brand.value.input0, <string>this.oem_brand.value.input1)) {
      this.oem_brand_status = "Missing target value"
      return
    }
    this.oem_brand_status = "Working on it"

    this.data.supplier_implementation.direct_hits[0].target_values.oem_info.brand = <string>this.oem_brand.value.input0
    this.data.supplier_implementation.direct_hits[1].target_values.oem_info.brand = <string>this.oem_brand.value.input1

    this.data.supplier_implementation.direct_hits[0].page_result.oem_info.brand = ""
    this.find_selectors(<string>this.oem_brand.value.input0, <string>this.oem_brand.value.input1, fast)
      .pipe(
        catchError((error) => {
          // @ts-ignore
          this.oem_brand_status = "Error"
          return EMPTY
        })
      )
      .subscribe((res) => {
        if (typeof res === "string") {
          this.oem_brand_status = res
          return
        }
        this.data.supplier_implementation.direct_hits_selectors.oem_info.brand = res as RankedDirectHitSelectors[]
        if (this.data.supplier_implementation.direct_hits_selectors.oem_info.brand.length > 0) {
          // @ts-ignore
          this.data.supplier_implementation.direct_hits[0].page_result.oem_info.brand = this.data.supplier_implementation.direct_hits_selectors.oem_info.brand[0].extracted_value

          // another request to get info for 2nd page
          const new_input = {
            "page_source": this.data.supplier_implementation.direct_hits[1].page_source,
            "value_to_extract": this.data.supplier_implementation.direct_hits_selectors.oem_info.brand[0].value_to_extract,
            "selector": this.data.supplier_implementation.direct_hits_selectors.oem_info.brand[0].selector,
          }

          const headers = new HttpHeaders({
            'auth': this.data.token
          });

          this.http.post(environment.apiUrl + "/extract_value", new_input, {headers}).subscribe((res) => {
            this.data.supplier_implementation.direct_hits[1].page_result.oem_info.brand = res as string
          })

          this.oem_brand_status = "Success"
        } else {
          this.oem_brand_status = "No selector found"
        }
      });
  }

  onSubmit_oem_number(fast: boolean) {
    if (<string>this.oem_number.value.input0 === '') {
      this.oem_number.value.input0 = this.data.supplier_implementation.direct_hits[0].target_values.oem_info.number
    }

    if (<string>this.oem_number.value.input1 === '') {
      this.oem_number.value.input1 = this.data.supplier_implementation.direct_hits[1].target_values.oem_info.number
    }

    if (!this.are_values_ok(<string>this.oem_number.value.input0, <string>this.oem_number.value.input1)) {
      this.oem_number_status = "Missing target value"
      return
    }
    this.oem_number_status = "Working on it"

    this.data.supplier_implementation.direct_hits[0].target_values.oem_info.number = <string>this.oem_number.value.input0
    this.data.supplier_implementation.direct_hits[1].target_values.oem_info.number = <string>this.oem_number.value.input1

    this.data.supplier_implementation.direct_hits[0].page_result.oem_info.number = ""
    this.find_selectors(<string>this.oem_number.value.input0, <string>this.oem_number.value.input1, fast,).pipe(
      catchError((error) => {
        // @ts-ignore
        this.oem_number_status = "Error"
        return EMPTY
      })
    )
      .subscribe((res) => {
        if (typeof res === "string") {
          this.oem_number_status = res
          return
        }
        this.data.supplier_implementation.direct_hits_selectors.oem_info.number = res as RankedDirectHitSelectors[]
        if (this.data.supplier_implementation.direct_hits_selectors.oem_info.number.length > 0) {
          // @ts-ignore
          this.data.supplier_implementation.direct_hits[0].page_result.oem_info.number = this.data.supplier_implementation.direct_hits_selectors.oem_info.number[0].extracted_value

          // another request to get info for 2nd page
          const new_input = {
            "page_source": this.data.supplier_implementation.direct_hits[1].page_source,
            "value_to_extract": this.data.supplier_implementation.direct_hits_selectors.oem_info.number[0].value_to_extract,
            "selector": this.data.supplier_implementation.direct_hits_selectors.oem_info.number[0].selector,
          }
          const headers = new HttpHeaders({
            'auth': this.data.token
          });

          this.http.post(environment.apiUrl + "/extract_value", new_input, {headers}).subscribe((res) => {
            this.data.supplier_implementation.direct_hits[1].page_result.oem_info.number = res as string
          })

          this.oem_number_status = "Success"
        } else {
          this.oem_number_status = "No selector found"
        }
      });
  }

  onSubmit_oem_gtin(fast: boolean) {
    if (<string>this.oem_gtin.value.input0 === '') {
      this.oem_gtin.value.input0 = this.data.supplier_implementation.direct_hits[0].target_values.oem_info.gtin
    }

    if (<string>this.oem_gtin.value.input1 === '') {
      this.oem_gtin.value.input1 = this.data.supplier_implementation.direct_hits[1].target_values.oem_info.gtin
    }

    if (!this.are_values_ok(<string>this.oem_gtin.value.input0, <string>this.oem_gtin.value.input1)) {
      this.oem_gtin_status = "Missing target value"
      return
    }
    this.oem_gtin_status = "Working on it"

    this.data.supplier_implementation.direct_hits[0].target_values.oem_info.gtin = <string>this.oem_gtin.value.input0
    this.data.supplier_implementation.direct_hits[1].target_values.oem_info.gtin = <string>this.oem_gtin.value.input1

    this.data.supplier_implementation.direct_hits[0].page_result.oem_info.gtin = ""
    this.find_selectors(<string>this.oem_gtin.value.input0, <string>this.oem_gtin.value.input1, fast).pipe(
      catchError((error) => {
        // @ts-ignore
        this.oem_gtin_status = "Error"
        return EMPTY
      })
    )
      .subscribe((res) => {
        if (typeof res === "string") {
          this.oem_gtin_status = res
          return
        }
        this.data.supplier_implementation.direct_hits_selectors.oem_info.gtin = res as RankedDirectHitSelectors[]
        if (this.data.supplier_implementation.direct_hits_selectors.oem_info.gtin.length > 0) {
          // @ts-ignore
          this.data.supplier_implementation.direct_hits[0].page_result.oem_info.gtin = this.data.supplier_implementation.direct_hits_selectors.oem_info.gtin[0].extracted_value

          // another request to get info for 2nd page
          const new_input = {
            "page_source": this.data.supplier_implementation.direct_hits[1].page_source,
            "value_to_extract": this.data.supplier_implementation.direct_hits_selectors.oem_info.gtin[0].value_to_extract,
            "selector": this.data.supplier_implementation.direct_hits_selectors.oem_info.gtin[0].selector,
          }
          const headers = new HttpHeaders({
            'auth': this.data.token
          });

          this.http.post(environment.apiUrl + "/extract_value", new_input, {headers}).subscribe((res) => {
            this.data.supplier_implementation.direct_hits[1].page_result.oem_info.gtin = res as string
          })

          this.oem_gtin_status = "Success"
        } else {
          this.oem_gtin_status = "No selector found"
        }
      });
  }

  onSubmit_internal_name(fast: boolean) {
    if (<string>this.internal_name.value.input0 === '') {
      this.internal_name.value.input0 = this.data.supplier_implementation.direct_hits[0].target_values.internal_info.name
    }

    if (<string>this.internal_name.value.input1 === '') {
      this.internal_name.value.input1 = this.data.supplier_implementation.direct_hits[1].target_values.internal_info.name
    }

    if (!this.are_values_ok(<string>this.internal_name.value.input0, <string>this.internal_name.value.input1)) {
      this.internal_name_status = "Missing target value"
      return
    }
    this.internal_name_status = "Working on it"

    this.data.supplier_implementation.direct_hits[0].target_values.internal_info.name = <string>this.internal_name.value.input0
    this.data.supplier_implementation.direct_hits[1].target_values.internal_info.name = <string>this.internal_name.value.input1

    this.data.supplier_implementation.direct_hits[0].page_result.internal_info.name = ""
    this.find_selectors(<string>this.internal_name.value.input0, <string>this.internal_name.value.input1, fast).pipe(
      catchError((error) => {
        // @ts-ignore
        this.internal_name_status = "Error"
        return EMPTY
      })
    )
      .subscribe((res) => {
        if (typeof res === "string") {
          this.internal_name_status = res
          return
        }
        this.data.supplier_implementation.direct_hits_selectors.internal_info.name = res as RankedDirectHitSelectors[]
        if (this.data.supplier_implementation.direct_hits_selectors.internal_info.name.length > 0) {
          // @ts-ignore
          this.data.supplier_implementation.direct_hits[0].page_result.internal_info.name = this.data.supplier_implementation.direct_hits_selectors.internal_info.name[0].extracted_value

          // another request to get info for 2nd page
          const new_input = {
            "page_source": this.data.supplier_implementation.direct_hits[1].page_source,
            "value_to_extract": this.data.supplier_implementation.direct_hits_selectors.internal_info.name[0].value_to_extract,
            "selector": this.data.supplier_implementation.direct_hits_selectors.internal_info.name[0].selector,
          }
          const headers = new HttpHeaders({
            'auth': this.data.token
          });

          this.http.post(environment.apiUrl + "/extract_value", new_input, {headers}).subscribe((res) => {
            this.data.supplier_implementation.direct_hits[1].page_result.internal_info.name = res as string
          })

          this.internal_name_status = "Success"
        } else {
          this.internal_name_status = "No selector found"
        }
      });
  }

  onSubmit_internal_number(fast: boolean) {
    if (<string>this.internal_number.value.input0 === '') {
      this.internal_number.value.input0 = this.data.supplier_implementation.direct_hits[0].target_values.internal_info.number
    }

    if (<string>this.internal_number.value.input1 === '') {
      this.internal_number.value.input1 = this.data.supplier_implementation.direct_hits[1].target_values.internal_info.number
    }

    if (!this.are_values_ok(<string>this.internal_number.value.input0, <string>this.internal_number.value.input1)) {
      this.internal_number_status = "Missing target value"
      return
    }
    this.internal_number_status = "Working on it"

    this.data.supplier_implementation.direct_hits[0].target_values.internal_info.number = <string>this.internal_number.value.input0
    this.data.supplier_implementation.direct_hits[1].target_values.internal_info.number = <string>this.internal_number.value.input1

    this.data.supplier_implementation.direct_hits[0].page_result.internal_info.number = ""
    this.find_selectors(<string>this.internal_number.value.input0, <string>this.internal_number.value.input1, fast).pipe(
      catchError((error) => {
        // @ts-ignore
        this.internal_number_status = "Error"
        return EMPTY
      })
    )
      .subscribe((res) => {
        if (typeof res === "string") {
          this.internal_number_status = res
          return
        }
        this.data.supplier_implementation.direct_hits_selectors.internal_info.number = res as RankedDirectHitSelectors[]
        if (this.data.supplier_implementation.direct_hits_selectors.internal_info.number.length > 0) {
          // @ts-ignore
          this.data.supplier_implementation.direct_hits[0].page_result.internal_info.number = this.data.supplier_implementation.direct_hits_selectors.internal_info.number[0].extracted_value

          // another request to get info for 2nd page
          const new_input = {
            "page_source": this.data.supplier_implementation.direct_hits[1].page_source,
            "value_to_extract": this.data.supplier_implementation.direct_hits_selectors.internal_info.number[0].value_to_extract,
            "selector": this.data.supplier_implementation.direct_hits_selectors.internal_info.number[0].selector,
          }

          const headers = new HttpHeaders({
            'auth': this.data.token
          });

          this.http.post(environment.apiUrl + "/extract_value", new_input, {headers}).subscribe((res) => {
            this.data.supplier_implementation.direct_hits[1].page_result.internal_info.number = res as string
          })

          this.internal_number_status = "Success"
        } else {
          this.internal_number_status = "No selector found"
        }
      });
  }

  onSubmit_internal_desc(fast: boolean) {
    if (<string>this.internal_desc.value.input0 === '') {
      this.internal_desc.value.input0 = this.data.supplier_implementation.direct_hits[0].target_values.internal_info.description
    }

    if (<string>this.internal_desc.value.input1 === '') {
      this.internal_desc.value.input1 = this.data.supplier_implementation.direct_hits[1].target_values.internal_info.description
    }

    if (!this.are_values_ok(<string>this.internal_desc.value.input0, <string>this.internal_desc.value.input1)) {
      this.internal_desc_status = "Missing target value"
      return
    }
    this.internal_desc_status = "Working on it"

    this.data.supplier_implementation.direct_hits[0].target_values.internal_info.description = <string>this.internal_desc.value.input0
    this.data.supplier_implementation.direct_hits[1].target_values.internal_info.description = <string>this.internal_desc.value.input1

    this.data.supplier_implementation.direct_hits[0].page_result.internal_info.description = ""
    this.find_selectors(<string>this.internal_desc.value.input0, <string>this.internal_desc.value.input1, fast).pipe(
      catchError((error) => {
        // @ts-ignore
        this.internal_desc_status = "Error"
        return EMPTY
      })
    )
      .subscribe((res) => {
        if (typeof res === "string") {
          this.internal_desc_status = res
          return
        }
        this.data.supplier_implementation.direct_hits_selectors.internal_info.description = res as RankedDirectHitSelectors[]
        if (this.data.supplier_implementation.direct_hits_selectors.internal_info.description.length > 0) {
          // @ts-ignore
          this.data.supplier_implementation.direct_hits[0].page_result.internal_info.description = this.data.supplier_implementation.direct_hits_selectors.internal_info.description[0].extracted_value

          // another request to get info for 2nd page
          const new_input = {
            "page_source": this.data.supplier_implementation.direct_hits[1].page_source,
            "value_to_extract": this.data.supplier_implementation.direct_hits_selectors.internal_info.description[0].value_to_extract,
            "selector": this.data.supplier_implementation.direct_hits_selectors.internal_info.description[0].selector,
          }
          const headers = new HttpHeaders({
            'auth': this.data.token
          });
          this.http.post(environment.apiUrl + "/extract_value", new_input, {headers}).subscribe((res) => {
            this.data.supplier_implementation.direct_hits[1].page_result.internal_info.description = res as string
          })

          this.internal_desc_status = "Success"
        } else {
          this.internal_desc_status = "No selector found"
        }
      });
  }

  onSubmit_internal_group(fast: boolean) {
    if (<string>this.internal_group.value.input0 === '') {
      this.internal_group.value.input0 = this.data.supplier_implementation.direct_hits[0].target_values.internal_info.group
    }

    if (<string>this.internal_group.value.input1 === '') {
      this.internal_group.value.input1 = this.data.supplier_implementation.direct_hits[1].target_values.internal_info.group
    }

    if (!this.are_values_ok(<string>this.internal_group.value.input0, <string>this.internal_group.value.input1)) {
      this.internal_group_status = "Missing target value"
      return
    }
    this.internal_group_status = "Working on it"

    this.data.supplier_implementation.direct_hits[0].target_values.internal_info.group = <string>this.internal_group.value.input0
    this.data.supplier_implementation.direct_hits[1].target_values.internal_info.group = <string>this.internal_group.value.input1

    this.data.supplier_implementation.direct_hits[0].page_result.internal_info.group = ""
    this.find_selectors(<string>this.internal_group.value.input0, <string>this.internal_group.value.input1, fast).pipe(
      catchError((error) => {
        // @ts-ignore
        this.internal_group_status = "Error"
        return EMPTY
      })
    )
      .subscribe((res) => {
        if (typeof res === "string") {
          this.internal_group_status = res
          return
        }
        this.data.supplier_implementation.direct_hits_selectors.internal_info.group = res as RankedDirectHitSelectors[]
        if (this.data.supplier_implementation.direct_hits_selectors.internal_info.group.length > 0) {
          // @ts-ignore
          this.data.supplier_implementation.direct_hits[0].page_result.internal_info.group = this.data.supplier_implementation.direct_hits_selectors.internal_info.group[0].extracted_value

          // another request to get info for 2nd page
          const new_input = {
            "page_source": this.data.supplier_implementation.direct_hits[1].page_source,
            "value_to_extract": this.data.supplier_implementation.direct_hits_selectors.internal_info.group[0].value_to_extract,
            "selector": this.data.supplier_implementation.direct_hits_selectors.internal_info.group[0].selector,
          }

          const headers = new HttpHeaders({
            'auth': this.data.token
          });

          this.http.post(environment.apiUrl + "/extract_value", new_input, {headers}).subscribe((res) => {
            this.data.supplier_implementation.direct_hits[1].page_result.internal_info.group = res as string
          })

          this.internal_group_status = "Success"
        } else {
          this.internal_group_status = "No selector found"
        }
      });
  }

  onSubmit_price_price(fast: boolean) {
    if (<string>this.price_price.value.input0 === '') {
      this.price_price.value.input0 = this.data.supplier_implementation.direct_hits[0].target_values.prices.price
    }

    if (<string>this.price_price.value.input1 === '') {
      this.price_price.value.input1 = this.data.supplier_implementation.direct_hits[1].target_values.prices.price
    }

    if (!this.are_values_ok(<string>this.price_price.value.input0, <string>this.price_price.value.input1)) {
      this.price_price_status = "Missing target value"
      return
    }
    this.price_price_status = "Working on it"
    this.parsed_price_1 = ""
    this.parsed_price_2 = ""

    this.data.supplier_implementation.direct_hits[0].target_values.prices.price = <string>this.price_price.value.input0
    this.data.supplier_implementation.direct_hits[1].target_values.prices.price = <string>this.price_price.value.input1

    this.data.supplier_implementation.direct_hits[0].page_result.prices.price = ""
    this.find_selectors(<string>this.price_price.value.input0, <string>this.price_price.value.input1, fast).pipe(
      catchError((error) => {
        // @ts-ignore
        this.price_price_status = "Error"
        return EMPTY
      })
    )
      .subscribe((res) => {
        if (typeof res === "string") {
          this.price_price_status = res
          return
        }

        this.data.supplier_implementation.direct_hits_selectors.prices.price = res as RankedDirectHitSelectors[]
        if (this.data.supplier_implementation.direct_hits_selectors.prices.price.length > 0) {
          // @ts-ignore
          this.data.supplier_implementation.direct_hits[0].page_result.prices.price = this.data.supplier_implementation.direct_hits_selectors.prices.price[0].extracted_value

          // another request to get info for 2nd page
          const new_input = {
            "page_source": this.data.supplier_implementation.direct_hits[1].page_source,
            "value_to_extract": this.data.supplier_implementation.direct_hits_selectors.prices.price[0].value_to_extract,
            "selector": this.data.supplier_implementation.direct_hits_selectors.prices.price[0].selector,
          }

          const headers = new HttpHeaders({
            'auth': this.data.token
          });


          this.http.post(environment.apiUrl + "/extract_value", new_input, {headers}).subscribe((res) => {
            this.data.supplier_implementation.direct_hits[1].page_result.prices.price = res as string
          })

          // another request to get the parsed value
          const value1_to_parse = {
            "raw_price": this.data.supplier_implementation.direct_hits[0].page_result.prices.price,
            "language": this.data.supplier_implementation.basic_supplier_info.language,
          }
          this.http.post(environment.apiUrl + "/get_parsed_price", value1_to_parse, {headers}).subscribe((res) => {
            this.parsed_price_1 = " [" + res as string + "]"
          })

          const value2_to_parse = {
            "raw_price": this.data.supplier_implementation.direct_hits[1].page_result.prices.price,
            "language": this.data.supplier_implementation.basic_supplier_info.language,
          }
          this.http.post(environment.apiUrl + "/get_parsed_price", value2_to_parse, {headers}).subscribe((res) => {
            this.parsed_price_2 = " [" + res as string + "]"
          })

          this.price_price_status = "Success"
        } else {
          this.price_price_status = "No selector found"
        }
      });
  }

  onSubmit_price_amount(fast: boolean) {
    if (<string>this.price_amount.value.input0 === '') {
      this.price_amount.value.input0 = this.data.supplier_implementation.direct_hits[0].target_values.prices.amount
    }

    if (<string>this.price_amount.value.input1 === '') {
      this.price_amount.value.input1 = this.data.supplier_implementation.direct_hits[1].target_values.prices.amount
    }

    if (!this.are_values_ok(<string>this.price_amount.value.input0, <string>this.price_amount.value.input1)) {
      this.price_amount_status = "Missing target value"
      return
    }
    this.price_amount_status = "Working on it"

    this.data.supplier_implementation.direct_hits[0].target_values.prices.amount = <string>this.price_amount.value.input0
    this.data.supplier_implementation.direct_hits[1].target_values.prices.amount = <string>this.price_amount.value.input1

    this.data.supplier_implementation.direct_hits[0].page_result.prices.amount = ""
    this.find_selectors(<string>this.price_amount.value.input0, <string>this.price_amount.value.input1, fast).pipe(
      catchError((error) => {
        // @ts-ignore
        this.price_amount_status = "Error"
        return EMPTY
      })
    )
      .subscribe((res) => {
        if (typeof res === "string") {
          this.price_amount_status = res
          return
        }

        this.data.supplier_implementation.direct_hits_selectors.prices.amount = res as RankedDirectHitSelectors[]
        if (this.data.supplier_implementation.direct_hits_selectors.prices.amount.length > 0) {
          // @ts-ignore
          this.data.supplier_implementation.direct_hits[0].page_result.prices.amount = this.data.supplier_implementation.direct_hits_selectors.prices.amount[0].extracted_value

          // another request to get info for 2nd page
          const new_input = {
            "page_source": this.data.supplier_implementation.direct_hits[1].page_source,
            "value_to_extract": this.data.supplier_implementation.direct_hits_selectors.prices.amount[0].value_to_extract,
            "selector": this.data.supplier_implementation.direct_hits_selectors.prices.amount[0].selector,
          }

          const headers = new HttpHeaders({
            'auth': this.data.token
          });


          this.http.post(environment.apiUrl + "/extract_value", new_input, {headers}).subscribe((res) => {
            this.data.supplier_implementation.direct_hits[1].page_result.prices.amount = res as string
          })

          this.price_amount_status = "Success"
        } else {
          this.price_amount_status = "No selector found"
        }
      });
  }

  onSubmit_price_delivery_text(fast: boolean) {
    if (<string>this.price_delivery_text.value.input0 === '') {
      this.price_delivery_text.value.input0 = this.data.supplier_implementation.direct_hits[0].target_values.prices.delivery_text
    }

    if (<string>this.price_delivery_text.value.input1 === '') {
      this.price_delivery_text.value.input1 = this.data.supplier_implementation.direct_hits[1].target_values.prices.delivery_text
    }

    if (!this.are_values_ok(<string>this.price_delivery_text.value.input0, <string>this.price_delivery_text.value.input1)) {
      this.price_delivery_text_status = "Missing target value"
      return
    }
    this.price_delivery_text_status = "Working on it"

    this.data.supplier_implementation.direct_hits[0].target_values.prices.delivery_text = <string>this.price_delivery_text.value.input0
    this.data.supplier_implementation.direct_hits[1].target_values.prices.delivery_text = <string>this.price_delivery_text.value.input1

    this.data.supplier_implementation.direct_hits[0].page_result.prices.delivery_text = ""
    this.find_selectors(<string>this.price_delivery_text.value.input0, <string>this.price_delivery_text.value.input1, fast).pipe(
      catchError((error) => {
        // @ts-ignore
        this.price_delivery_text_status = "Error"
        return EMPTY
      })
    )
      .subscribe((res) => {
        if (typeof res === "string") {
          this.price_delivery_text_status = res
          return
        }

        this.data.supplier_implementation.direct_hits_selectors.prices.delivery_text = res as RankedDirectHitSelectors[]
        if (this.data.supplier_implementation.direct_hits_selectors.prices.delivery_text.length > 0) {
          // @ts-ignore
          this.data.supplier_implementation.direct_hits[0].page_result.prices.delivery_text = this.data.supplier_implementation.direct_hits_selectors.prices.delivery_text[0].extracted_value

          // another request to get info for 2nd page
          const new_input = {
            "page_source": this.data.supplier_implementation.direct_hits[1].page_source,
            "value_to_extract": this.data.supplier_implementation.direct_hits_selectors.prices.delivery_text[0].value_to_extract,
            "selector": this.data.supplier_implementation.direct_hits_selectors.prices.delivery_text[0].selector,
          }

          const headers = new HttpHeaders({
            'auth': this.data.token
          });


          this.http.post(environment.apiUrl + "/extract_value", new_input, {headers}).subscribe((res) => {
            this.data.supplier_implementation.direct_hits[1].page_result.prices.delivery_text = res as string
          })

          this.price_delivery_text_status = "Success"
        } else {
          this.price_delivery_text_status = "No selector found"
        }
      });
  }

  onSubmit_condition(fast: boolean) {
    if (<string>this.condition.value.input0 === '') {
      this.condition.value.input0 = this.data.supplier_implementation.direct_hits[0].target_values.condition
    }

    if (<string>this.condition.value.input1 === '') {
      this.condition.value.input1 = this.data.supplier_implementation.direct_hits[1].target_values.condition
    }

    if (!this.are_values_ok(<string>this.condition.value.input0, <string>this.condition.value.input1)) {
      this.condition_status = "Missing target value"
      return
    }
    this.condition_status = "Working on it"

    this.data.supplier_implementation.direct_hits[0].target_values.condition = <string>this.condition.value.input0
    this.data.supplier_implementation.direct_hits[1].target_values.condition = <string>this.condition.value.input1

    this.data.supplier_implementation.direct_hits[0].page_result.condition = ""
    this.find_selectors(<string>this.condition.value.input0, <string>this.condition.value.input1, fast).pipe(
      catchError((error) => {
        // @ts-ignore
        this.condition_status = "Error"
        return EMPTY
      })
    )
      .subscribe((res) => {
        if (typeof res === "string") {
          this.condition_status = res
          return
        }

        this.data.supplier_implementation.direct_hits_selectors.condition = res as RankedDirectHitSelectors[]
        if (this.data.supplier_implementation.direct_hits_selectors.condition.length > 0) {
          // @ts-ignore
          this.data.supplier_implementation.direct_hits[0].page_result.condition = this.data.supplier_implementation.direct_hits_selectors.condition[0].extracted_value

          // another request to get info for 2nd page
          const new_input = {
            "page_source": this.data.supplier_implementation.direct_hits[1].page_source,
            "value_to_extract": this.data.supplier_implementation.direct_hits_selectors.condition[0].value_to_extract,
            "selector": this.data.supplier_implementation.direct_hits_selectors.condition[0].selector,
          }

          const headers = new HttpHeaders({
            'auth': this.data.token
          });


          this.http.post(environment.apiUrl + "/extract_value", new_input, {headers}).subscribe((res) => {
            this.data.supplier_implementation.direct_hits[1].page_result.condition = res as string
          })

          this.condition_status = "Success"
        } else {
          this.condition_status = "No selector found"
        }
      });
  }

  overlay_off() {
    // @ts-ignore
    document.getElementById("overlay").style.display = "none";
  }


  showSelectors(value: string) {
    let maxim: number = 30
    let selectors: string = "Top " + maxim + " selectors:\n\n";

    let overlay_text = document.getElementById("text")


    switch (value) {
      case "oem_brand": {
        if (this.data.supplier_implementation.direct_hits_selectors.oem_info.brand.length > 0) {
          for (let i = 0; i < this.data.supplier_implementation.direct_hits_selectors.oem_info.brand.length && i < maxim; i++) {
            selectors = selectors + this.data.supplier_implementation.direct_hits_selectors.oem_info.brand[i].selector + "\n";
          }
        }
        break;
      }
      case "oem_number": {
        if (this.data.supplier_implementation.direct_hits_selectors.oem_info.number.length > 0) {
          for (let i = 0; i < this.data.supplier_implementation.direct_hits_selectors.oem_info.number.length && i < maxim; i++) {
            selectors = selectors + this.data.supplier_implementation.direct_hits_selectors.oem_info.number[i].selector + "\n";
          }
        }
        break;
      }
      case "oem_gtin": {
        if (this.data.supplier_implementation.direct_hits_selectors.oem_info.gtin.length > 0) {
          for (let i = 0; i < this.data.supplier_implementation.direct_hits_selectors.oem_info.gtin.length && i < maxim; i++) {
            selectors = selectors + this.data.supplier_implementation.direct_hits_selectors.oem_info.gtin[i].selector + "\n";
          }
        }
        break;
      }
      case "internal_number": {
        if (this.data.supplier_implementation.direct_hits_selectors.internal_info.number.length > 0) {
          for (let i = 0; i < this.data.supplier_implementation.direct_hits_selectors.internal_info.number.length && i < maxim; i++) {
            selectors = selectors + this.data.supplier_implementation.direct_hits_selectors.internal_info.number[i].selector + "\n";
          }
        }
        break;
      }
      case "internal_name": {
        if (this.data.supplier_implementation.direct_hits_selectors.internal_info.name.length > 0) {
          for (let i = 0; i < this.data.supplier_implementation.direct_hits_selectors.internal_info.name.length && i < maxim; i++) {
            selectors = selectors + this.data.supplier_implementation.direct_hits_selectors.internal_info.name[i].selector + "\n";
          }
        }
        break;
      }
      case "internal_desc": {
        if (this.data.supplier_implementation.direct_hits_selectors.internal_info.description.length > 0) {
          for (let i = 0; i < this.data.supplier_implementation.direct_hits_selectors.internal_info.description.length && i < maxim; i++) {
            selectors = selectors + this.data.supplier_implementation.direct_hits_selectors.internal_info.description[i].selector + "\n";
          }
        }
        break;
      }
      case "internal_group": {
        if (this.data.supplier_implementation.direct_hits_selectors.internal_info.group.length > 0) {
          for (let i = 0; i < this.data.supplier_implementation.direct_hits_selectors.internal_info.group.length && i < maxim; i++) {
            selectors = selectors + this.data.supplier_implementation.direct_hits_selectors.internal_info.group[i].selector + "\n";
          }
        }
        break;
      }
      case "price_price": {
        if (this.data.supplier_implementation.direct_hits_selectors.prices.price.length > 0) {
          for (let i = 0; i < this.data.supplier_implementation.direct_hits_selectors.prices.price.length && i < maxim; i++) {
            selectors = selectors + this.data.supplier_implementation.direct_hits_selectors.prices.price[i].selector + "\n";
          }
        }
        break;
      }
      case "price_amount": {
        if (this.data.supplier_implementation.direct_hits_selectors.prices.amount.length > 0) {
          for (let i = 0; i < this.data.supplier_implementation.direct_hits_selectors.prices.amount.length && i < maxim; i++) {
            selectors = selectors + this.data.supplier_implementation.direct_hits_selectors.prices.amount[i].selector + "\n";
          }
        }
        break;
      }
      case "price_delivery_text": {
        if (this.data.supplier_implementation.direct_hits_selectors.prices.delivery_text.length > 0) {
          for (let i = 0; i < this.data.supplier_implementation.direct_hits_selectors.prices.delivery_text.length && i < maxim; i++) {
            selectors = selectors + this.data.supplier_implementation.direct_hits_selectors.prices.delivery_text[i].selector + "\n";
          }
        }
        break;
      }
      case "condition": {
        if (this.data.supplier_implementation.direct_hits_selectors.condition.length > 0) {
          for (let i = 0; i < this.data.supplier_implementation.direct_hits_selectors.condition.length && i < maxim; i++) {
            selectors = selectors + this.data.supplier_implementation.direct_hits_selectors.condition[i].selector + "\n";
          }
        }
        break;
      }
    }
    // @ts-ignore
    overlay_text.innerText = selectors
    // @ts-ignore
    document.getElementById("overlay").style.display = "block";
  }


  oem_brand = new FormGroup({
    input0: new FormControl(this.data.supplier_implementation.direct_hits[0].target_values.oem_info.brand),
    input1: new FormControl(this.data.supplier_implementation.direct_hits[1].target_values.oem_info.brand)
  });
  oem_number = new FormGroup({
    input0: new FormControl(this.data.supplier_implementation.direct_hits[0].target_values.oem_info.number),
    input1: new FormControl(this.data.supplier_implementation.direct_hits[1].target_values.oem_info.number)
  });
  oem_gtin = new FormGroup({
    input0: new FormControl(this.data.supplier_implementation.direct_hits[0].target_values.oem_info.gtin),
    input1: new FormControl(this.data.supplier_implementation.direct_hits[1].target_values.oem_info.gtin)
  });

  internal_name = new FormGroup({
    input0: new FormControl(this.data.supplier_implementation.direct_hits[0].target_values.internal_info.name),
    input1: new FormControl(this.data.supplier_implementation.direct_hits[1].target_values.internal_info.name)
  });

  internal_number = new FormGroup({
    input0: new FormControl(this.data.supplier_implementation.direct_hits[0].target_values.internal_info.number),
    input1: new FormControl(this.data.supplier_implementation.direct_hits[1].target_values.internal_info.number)
  });

  internal_desc = new FormGroup({
    input0: new FormControl(this.data.supplier_implementation.direct_hits[0].target_values.internal_info.description),
    input1: new FormControl(this.data.supplier_implementation.direct_hits[1].target_values.internal_info.description)
  });

  internal_group = new FormGroup({
    input0: new FormControl(this.data.supplier_implementation.direct_hits[0].target_values.internal_info.group),
    input1: new FormControl(this.data.supplier_implementation.direct_hits[1].target_values.internal_info.group)
  });

  price_amount = new FormGroup({
    input0: new FormControl(this.data.supplier_implementation.direct_hits[0].target_values.prices.amount),
    input1: new FormControl(this.data.supplier_implementation.direct_hits[1].target_values.prices.amount)
  });

  price_price = new FormGroup({
    input0: new FormControl(this.data.supplier_implementation.direct_hits[0].target_values.prices.price),
    input1: new FormControl(this.data.supplier_implementation.direct_hits[1].target_values.prices.price)
  });

  price_delivery_text = new FormGroup({
    input0: new FormControl(this.data.supplier_implementation.direct_hits[0].target_values.prices.delivery_text),
    input1: new FormControl(this.data.supplier_implementation.direct_hits[1].target_values.prices.delivery_text)
  });

  condition = new FormGroup({
    input0: new FormControl(this.data.supplier_implementation.direct_hits[0].target_values.condition),
    input1: new FormControl(this.data.supplier_implementation.direct_hits[1].target_values.condition)
  });

  use_all_schema_values() {
    this.use_schema_oem_info_brand = this.data.supplier_implementation.direct_hits_selectors.use_schema.oem_info_brand = true
    this.use_schema_oem_info_number = this.data.supplier_implementation.direct_hits_selectors.use_schema.oem_info_number = true
    this.use_schema_oem_info_gtin = this.data.supplier_implementation.direct_hits_selectors.use_schema.oem_info_gtin = true
    this.use_schema_internal_info_number = this.data.supplier_implementation.direct_hits_selectors.use_schema.internal_info_number = true
    this.use_schema_internal_info_name = this.data.supplier_implementation.direct_hits_selectors.use_schema.internal_info_name = true
    this.use_schema_internal_info_description = this.data.supplier_implementation.direct_hits_selectors.use_schema.internal_info_description = true
    this.use_schema_internal_info_group = this.data.supplier_implementation.direct_hits_selectors.use_schema.internal_info_group = true
    this.use_schema_prices_price = this.data.supplier_implementation.direct_hits_selectors.use_schema.prices_price = true
  }

  use_schema_oem_info_brand: boolean = this.data.supplier_implementation.direct_hits_selectors.use_schema.oem_info_brand
  use_schema_oem_info_number: boolean = this.data.supplier_implementation.direct_hits_selectors.use_schema.oem_info_number
  use_schema_oem_info_gtin: boolean = this.data.supplier_implementation.direct_hits_selectors.use_schema.oem_info_gtin
  use_schema_internal_info_number: boolean = this.data.supplier_implementation.direct_hits_selectors.use_schema.internal_info_number
  use_schema_internal_info_name: boolean = this.data.supplier_implementation.direct_hits_selectors.use_schema.internal_info_name
  use_schema_internal_info_description: boolean = this.data.supplier_implementation.direct_hits_selectors.use_schema.internal_info_description
  use_schema_internal_info_group: boolean = this.data.supplier_implementation.direct_hits_selectors.use_schema.internal_info_group
  use_schema_prices_price: boolean = this.data.supplier_implementation.direct_hits_selectors.use_schema.prices_price

  onChangeSchemaBrand() {
    this.data.supplier_implementation.direct_hits_selectors.use_schema.oem_info_brand = this.use_schema_oem_info_brand
    console.log(this.data.supplier_implementation)
  }

  onChangeSchemaOemNumber() {
    this.data.supplier_implementation.direct_hits_selectors.use_schema.oem_info_number = this.use_schema_oem_info_number
  }

  onChangeSchemaOemGtin() {
    this.data.supplier_implementation.direct_hits_selectors.use_schema.oem_info_gtin = this.use_schema_oem_info_gtin
  }

  onChangeSchemaInternalNumber() {
    this.data.supplier_implementation.direct_hits_selectors.use_schema.internal_info_number = this.use_schema_internal_info_number
  }

  onChangeSchemaInternalName() {
    this.data.supplier_implementation.direct_hits_selectors.use_schema.internal_info_name = this.use_schema_internal_info_name
  }

  onChangeSchemaInternalDescription() {
    this.data.supplier_implementation.direct_hits_selectors.use_schema.internal_info_description = this.use_schema_internal_info_description
  }

  onChangeSchemaInternalGroup() {
    this.data.supplier_implementation.direct_hits_selectors.use_schema.internal_info_group = this.use_schema_internal_info_group
  }

  onChangeSchemaPrice() {
    this.data.supplier_implementation.direct_hits_selectors.use_schema.prices_price = this.use_schema_prices_price
    this.parsed_price_1 = ""
    this.parsed_price_2 = ""
  }
}
