<div class="container">
  <div class="row">
    <div class="col-1"></div>
    <div class="col-10">
      <table style=width:100%>
        <tr>
          <th>Field </th>
          <th style=width:60%>Value</th>
        </tr>
        <tr>
          <td>Supplier name</td>
          <td>
              <input type="text" [(ngModel)]="this.data.supplier_implementation.basic_supplier_info.name" disabled>
          </td>
        </tr>
        <tr>
          <td>Supplier Id</td>
          <td>
              <input type="text" [(ngModel)]="this.data.supplier_implementation.basic_supplier_info.supplier_id" disabled>
          </td>
        </tr>
        <tr>
          <td>Supplier country <span matTooltip="When in doubt about the location of the shop, use the imprint/impressum">(?)</span></td>
          <td>

              <select [(ngModel)]="this.data.supplier_implementation.basic_supplier_info.country" required>
                <option value="">Select a country</option>
                <option *ngFor="let code of countries" [value]="code">{{ code }}</option>
              </select>

          </td>
        </tr>
        <tr>
          <td>Supplier currency <span matTooltip="This is the primary currency of the shop, so without changing language, etc. (when navigating to the shop with the base url)">(?)</span></td>
          <td>
              <select [(ngModel)]="this.data.supplier_implementation.basic_supplier_info.currency" required>
                <option value="">Select a currency</option>
                <option *ngFor="let code of currencies" [value]="code">{{ code }}</option>
              </select>
          </td>
        </tr>
        <tr>
          <td>Is VAT included in the price displayed on the page?</td>
          <td>
              <input type="checkbox" [(ngModel)]="this.data.supplier_implementation.basic_supplier_info.vat_included_on_displayed_price">
          </td>
        </tr>
        <tr>
          <td>Supplier language <span matTooltip="The language that the website is in when navigating to the shop with the base url and not change settings">(?)</span></td>
          <td>

              <select [(ngModel)]="this.data.supplier_implementation.basic_supplier_info.language" required>
                <option value="">Select a language</option>
                <option *ngFor="let code of languages" [value]="code">{{ code }}</option>
              </select>

          </td>
        </tr>
        <tr>
          <td>Base URL</td>
          <td>
              <input type="text" [(ngModel)]="this.data.supplier_implementation.basic_supplier_info.base_url">
          </td>
        </tr>
        <tr>
          <td>Search URL (replace search term with {{ '{' }}number{{ '}' }})</td>
          <td>
              <input type="text" [(ngModel)]="this.data.supplier_implementation.basic_supplier_info.search_url">
          </td>
        </tr>
        <tr>
          <td colspan="2"><br></td>
        </tr>


      </table>
    </div>
  </div>
</div>

