import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SupplierImplementation} from './model';

@Injectable({
  providedIn: 'root'
})

export class AutoScraperBackendService {
  public supplier_implementation!: SupplierImplementation;
  public token: string
  public is_logged_in: boolean

  constructor(public http: HttpClient) {
    this.token = ""
    this.is_logged_in = false
  }
}
