import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
import { DirectHitComponent } from './direct-hit/direct-hit.component';
import { MetaComponent } from './meta/meta.component';
import { ImplementationInfoComponent } from './implementation-info/implementation-info.component';
import { MultipleHitsComponent } from './multiple-hits/multiple-hits.component';
import { NoHitsComponent } from './no-hits/no-hits.component';
import { PageClassificationComponent } from './page-classification/page-classification.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoginComponent } from './login/login.component';


@NgModule({
  declarations: [
    AppComponent,
    TopNavbarComponent,
    DirectHitComponent,
    MetaComponent,
    ImplementationInfoComponent,
    MultipleHitsComponent,
    NoHitsComponent,
    PageClassificationComponent,
    HomeComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
