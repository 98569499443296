<nav class="navbar navbar-expand-lg navbar-light bg-dark fixed-top">
  <div class="container-fluid">
    <div class="navbar" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item" *ngIf="this.data.is_logged_in">
          <a class="nav-link link-light" [routerLink]="['/home']"><i class="fas fa-home"></i> </a>
        </li>
        <li class="nav-item" *ngIf="this.data.is_logged_in && this.data.supplier_implementation">
          <a class="nav-link link-light" [routerLink]="['/meta']">Meta</a>
        </li>
        <li class="nav-item" *ngIf="this.data.is_logged_in && this.data.supplier_implementation">
          <a class="nav-link link-light" [routerLink]="['/direct_hit']">DirectHit</a>
        </li>
        <li class="nav-item" *ngIf="this.data.is_logged_in && this.data.supplier_implementation">
          <a class="nav-link link-light" [routerLink]="['/multiple_hits']">MultiHits</a>
        </li>
        <li class="nav-item" *ngIf="this.data.is_logged_in && this.data.supplier_implementation">
          <a class="nav-link link-light" [routerLink]="['/no_hits']">NoHits</a>
        </li>
        <li class="nav-item" *ngIf="this.data.is_logged_in && this.data.supplier_implementation">
          <a class="nav-link link-light" [routerLink]="['/page_type']">PageClassification</a>
        </li>
        <li class="nav-item" *ngIf="this.data.is_logged_in && this.data.supplier_implementation">
          <a class="nav-link link-light" [routerLink]="['/info']">Save</a>
        </li>
        <li class="nav-item" *ngIf="!this.data.is_logged_in">
          <a class="nav-link link-light" [routerLink]="['/login']">{{this.login_text}}</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<br>
<br>
<br>
<br>
