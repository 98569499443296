import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DirectHitComponent} from "./direct-hit/direct-hit.component";
import {ImplementationInfoComponent} from "./implementation-info/implementation-info.component";
import {NoHitsComponent} from "./no-hits/no-hits.component";
import {MetaComponent} from "./meta/meta.component";
import {MultipleHitsComponent} from "./multiple-hits/multiple-hits.component";
import {PageClassificationComponent} from "./page-classification/page-classification.component";
import {HomeComponent} from "./home/home.component";
import {LoginComponent} from "./login/login.component";

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'home', component: HomeComponent},
  {path: 'meta', component: MetaComponent},
  {path: 'direct_hit', component: DirectHitComponent},
  {path: 'multiple_hits', component: MultipleHitsComponent},
  {path: 'no_hits', component: NoHitsComponent},
  {path: 'page_type', component: PageClassificationComponent},
  {path: 'info', component: ImplementationInfoComponent},
  {path: 'login', component: LoginComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
