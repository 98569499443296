import { Component } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AutoScraperBackendService} from "../auto-scraper-backend.service";

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.css']
})
export class TopNavbarComponent {
  login_text: string = "Login"

    constructor(public data: AutoScraperBackendService) {
  }
}
