import {Component} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AutoScraperBackendService} from "../auto-scraper-backend.service";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  username: string = '';
  password: string = '';
  apiUrl: string = environment.apiUrl+'/login';
  login_status: string = ''

  constructor(private http: HttpClient, public data: AutoScraperBackendService, private router: Router) {
  }

  onLogin(): void {
    let user = {'username': this.username, 'password': this.password}

    this.http.post(this.apiUrl, user)
      .subscribe((res) => {
        if (res === "Wrong username/password") {
          this.login_status = "Wrong username/password"
        } else {
          this.data.token = res as string
          this.data.is_logged_in = true

          this.router.navigate(['/home'])
        }
      });
  }
}
