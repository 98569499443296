import {Component} from '@angular/core';
import {AutoScraperBackendService} from '../auto-scraper-backend.service';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {EMPTY} from "rxjs";
import {environment} from "../../environments/environment";
import {PageSource, SupplierImplementation} from "../model";

@Component({
  selector: 'app-implementation-info',
  templateUrl: './implementation-info.component.html',
  styleUrls: ['./implementation-info.component.css']
})

export class ImplementationInfoComponent {
  constructor(private http: HttpClient, public data: AutoScraperBackendService) {
  }

  saveImplementation(): void {
    if (this.data.supplier_implementation.basic_supplier_info.name == null) {
      let status_element = document.getElementById("status")
      // @ts-ignore
      status_element.innerText = "error. empty supplier name."
      return
    }
    const headers = new HttpHeaders({
      'auth': this.data.token
    });

    this.http.post(environment.apiUrl + "/save_implementation", this.data.supplier_implementation, {headers})
      .pipe(
        catchError((error) => {
          let status_element = document.getElementById("status")
          // @ts-ignore
          status_element.innerText = "Error"
          return EMPTY
        })
      )
      .subscribe((res) => {
        let status_element = document.getElementById("status")
        if (res === "Unauthorized.") {
          // @ts-ignore
          status_element.innerText = "Unauthorized."
          return
        }
        if (typeof (res) === "string") {
          // @ts-ignore
          status_element.innerText = res
          return
        }
        this.data.supplier_implementation = res as SupplierImplementation
        // @ts-ignore
        status_element.innerText = "saved"
      });
  }
}
