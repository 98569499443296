<div class="container">
  <div class="row">
    <h2>Multi Hits</h2>
    <br><br>
  </div>

  <br>

  <div class="row">
    <div style="display: flex;">
      <div style="width: 50%; background-color: lightblue;">
        <b>Multi Hits 1</b>
        <div class="row">
          <form #getPageSource_0="ngForm" (ngSubmit)="get_page_source_from_url(0, getPageSource_0.value)">
            Enter url to download:
            <input type="text" name="url" [(ngModel)]="this.data.supplier_implementation.multiple_hits.search_items[0].page_source_url">
            <button class="btn btn-secondary">Get page source</button>
            <b id="source_status_mh_0"></b>
          </form>
        </div>

        <div class="row">
          <div class="col-1"></div>
          or
        </div>

        <div class="row">
          <form #addPastedPageSource_0="ngForm" (ngSubmit)="add_pasted_page_source(0, addPastedPageSource_0.value)">
            Paste page source:
            <input type="text" name="pasted_source"
                   [(ngModel)]="this.data.supplier_implementation.multiple_hits.search_items[0].page_source">
            <button class="btn btn-secondary">Add page source</button>
            <b id="source_status2_mh_0"></b>
          </form>
        </div>


      </div>
      <div style="width: 50%; background-color: lightpink;">
        <b>Multi Hits 2</b>
        <div class="row">
          <form #getPageSource_1="ngForm" (ngSubmit)="get_page_source_from_url(1, getPageSource_1.value)">
            Enter url to download:
            <input type="text" name="url" [(ngModel)]="this.data.supplier_implementation.multiple_hits.search_items[1].page_source_url">
            <button class="btn btn-secondary">Get page source</button>
            <b id="source_status_mh_1"></b>
          </form>
        </div>

        <div class="row">
          <div class="col-1"></div>
          or
        </div>

        <div class="row">
          <form #addPastedPageSource_1="ngForm" (ngSubmit)="add_pasted_page_source(1, addPastedPageSource_1.value)">
            Paste page source:
            <input type="text" name="pasted_source"
                   [(ngModel)]="this.data.supplier_implementation.multiple_hits.search_items[1].page_source">
            <button class="btn btn-secondary">Add page source</button>
            <b id="source_status2_mh_1"></b>
          </form>
        </div>


      </div>
    </div>
  </div>

  <br><br>
  <div>
    <input type="checkbox" [(ngModel)]="simple_search" (change)="onSimpleSearchChange()">
    Do results show brand and number (simple search)?
  </div>

  <br><br>



<!-------------------------------------- TABLE ------------------------------------>
  <table class="table table-striped" style="border-collapse: collapse; table-layout:fixed;">
    <thead class="thead-dark" style="font-weight: bold">
    <tr>
      <th></th>
      <th colspan="1" style="border: 1px solid black; background-color: lightblue;">Multiple Hits 1</th>
      <th colspan="1" style="border: 1px solid black; background-color: lightpink;">Multiple Hits 2</th>
    </tr>
    <tr>
      <th style="border: 1px solid black;">Field</th>
      <th style="border: 1px solid black; background-color: lightblue;">Target Value</th>
      <th style="border: 1px solid black; background-color: lightpink;">Target Value</th>
      <th style="border: 1px solid black;">Selectors</th>
    </tr>
    </thead>
    <tbody>

    <tr>
      <td style="border: 1px solid black;">Url1</td>
      <td style="border: 1px solid black; background-color: lightblue;">
        <form [formGroup]="multi_hit_selector_search">
          <input type="text" value="{{this.data.supplier_implementation.multiple_hits.search_items[0].elements[0].url}}"
                 formControlName="page_0_url0">
        </form>
      </td>
      <td style="border: 1px solid black; background-color: lightpink;">
        <form [formGroup]="multi_hit_selector_search">
          <input type="text" value="{{this.data.supplier_implementation.multiple_hits.search_items[1].elements[0].url}}"
                 formControlName="page_1_url0">
        </form>
      </td>
      <td style="border: 1px solid black;">
        <button class="btn btn-secondary" (click)="showSelectors('url')"
                *ngIf="this.data.supplier_implementation.multiple_hits_selectors.selectors.length > 0">
          Show Selectors
        </button>
      </td>
    </tr>


    <tr *ngIf="simple_search">
      <td style="border: 1px solid black;">Brand1</td>
      <td style="border: 1px solid black; background-color: lightblue;">
        <form [formGroup]="multi_hit_selector_search">
          <input type="text"
                 value=" {{this.data.supplier_implementation.multiple_hits.search_items[0].elements[0].brand}}"
                 formControlName="page_0_brand0">
        </form>
      </td>
      <td style="border: 1px solid black; background-color: lightpink;">
        <form [formGroup]="multi_hit_selector_search">
          <input type="text"
                 value="{{this.data.supplier_implementation.multiple_hits.search_items[1].elements[0].brand}}"
                 formControlName="page_1_brand0">
        </form>
      </td>
      <td style="border: 1px solid black;">
        <button class="btn btn-secondary" (click)="showSelectors('brand')"
                *ngIf="this.data.supplier_implementation.multiple_hits_selectors.selectors.length > 0">Show
          Selectors
        </button>
    </tr>

    <tr *ngIf="simple_search">
      <td style="border: 1px solid black;">Number1</td>
      <td style="border: 1px solid black; background-color: lightblue;">
        <form [formGroup]="multi_hit_selector_search">
          <input type="text"
                 value=" {{this.data.supplier_implementation.multiple_hits.search_items[0].elements[0].number}}"
                 formControlName="page_0_number0">
        </form>
      </td>
      <td style="border: 1px solid black; background-color: lightpink;">
        <form [formGroup]="multi_hit_selector_search">
          <input type="text"
                 value="{{this.data.supplier_implementation.multiple_hits.search_items[1].elements[0].number}}"
                 formControlName="page_1_number0">
        </form>
      </td>
      <td style="border: 1px solid black;">
        <button class="btn btn-secondary" (click)="showSelectors('number')"
                *ngIf="this.data.supplier_implementation.multiple_hits_selectors.selectors.length > 0">Show
          Selectors
        </button>
    </tr>
    <tr>
      <td style="border: 1px solid black;">Url2</td>
      <td style="border: 1px solid black; background-color: lightblue;">
        <form [formGroup]="multi_hit_selector_search">
          <input type="text" value="{{this.data.supplier_implementation.multiple_hits.search_items[0].elements[1].url}}"
                 formControlName="page_0_url1">
        </form>
      </td>
      <td style="border: 1px solid black; background-color: lightpink;">
        <form [formGroup]="multi_hit_selector_search">
          <input type="text" value="{{this.data.supplier_implementation.multiple_hits.search_items[1].elements[1].url}}"
                 formControlName="page_1_url1">
        </form>
      </td>
      <td style="border: 1px solid black;">
        <button class="btn btn-secondary" (click)="showSelectors('url')"
                *ngIf="this.data.supplier_implementation.multiple_hits_selectors.selectors.length > 0">
          Show Selectors
        </button>
      </td>
    </tr>


    <tr *ngIf="simple_search">
      <td style="border: 1px solid black;">Brand2</td>
      <td style="border: 1px solid black; background-color: lightblue;">
        <form [formGroup]="multi_hit_selector_search">
          <input type="text"
                 value=" {{this.data.supplier_implementation.multiple_hits.search_items[0].elements[1].brand}}"
                 formControlName="page_0_brand1">
        </form>
      </td>
      <td style="border: 1px solid black; background-color: lightpink;">
        <form [formGroup]="multi_hit_selector_search">
          <input type="text"
                 value="{{this.data.supplier_implementation.multiple_hits.search_items[1].elements[1].brand}}"
                 formControlName="page_1_brand1">
        </form>
      </td>
      <td style="border: 1px solid black;">
        <button class="btn btn-secondary" (click)="showSelectors('brand')"
                *ngIf="this.data.supplier_implementation.multiple_hits_selectors.selectors.length > 0">Show
          Selectors
        </button>
    </tr>

    <tr *ngIf="simple_search">
      <td style="border: 1px solid black;">Number2</td>
      <td style="border: 1px solid black; background-color: lightblue;">
        <form [formGroup]="multi_hit_selector_search">
          <input type="text"
                 value=" {{this.data.supplier_implementation.multiple_hits.search_items[0].elements[1].number}}"
                 formControlName="page_0_number1">
        </form>
      </td>
      <td style="border: 1px solid black; background-color: lightpink;">
        <form [formGroup]="multi_hit_selector_search">
          <input type="text"
                 value="{{this.data.supplier_implementation.multiple_hits.search_items[1].elements[1].number}}"
                 formControlName="page_1_number1">
        </form>
      </td>
      <td style="border: 1px solid black;">
        <button class="btn btn-secondary" (click)="showSelectors('number')"
                *ngIf="this.data.supplier_implementation.multiple_hits_selectors.selectors.length > 0">Show
          Selectors
        </button>
    </tr>
    </tbody>
  </table>

  <div class="row">
    <div class="col-9"></div>
    <div class="col-2">{{multi_hits_status}}</div>
    <div class="col-1">
      <button class="btn btn-secondary" (click)="onSubmit_multi_hit_selector_search()">GO!</button>
    </div>
  </div>
</div>


<!-- overlay stuff -->
<div id="overlay" (click)="overlay_off()">
  <div id="text"></div>
</div>
