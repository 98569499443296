<div class="container">
  <div class="row">
    <div class="col-12 text-center" id="load_status">&nbsp;</div>
  </div>

  <div class="row">
    <div class="col-12"><h2>Search for supplier</h2></div>
    <div class="col-12">
      <div class="search-container">
        <form (submit)="search()">
          <input type="text" [(ngModel)]="searchQuery" placeholder="Enter your search query" name="searchQuery">
          <button type="submit">Search</button>
        </form>
      </div>
      <br>
    </div>

    <div class="col-12">
      <table class="table" style="border-collapse: collapse; table-layout:fixed;"
             *ngIf="searchResults.length > 0">
        <thead>
        <th>Supplier Name</th>
        <th>URL</th>
        <th>Create/Load Implementation</th>
        </thead>
        <tr *ngFor="let result of searchResults">
          <td>{{ result.name}}</td>
          <td>{{ result.url}}</td>
          <td>
            <button (click)="create_or_load_implementation(result.id)">Create/Load
              Implementation
            </button>
          </td>
        </tr>
      </table>
      <p *ngIf="searchResults.length === 0">No results found.</p>
    </div>
  </div>

  <!--
  <br>
  <br><br>
  <div class="row">
    <div class="col-12"><h2>Autoscraper Implementations</h2></div>
    <div class="col-12">
      <table class="table table-striped" style="border-collapse: collapse; table-layout:fixed;">
        <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Country</th>
          <th>URL</th>
          <th>Load Supplier Implementation</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let supplier of suppliers">
          <td>{{ supplier._id }}</td>
          <td>
            <ng-container *ngIf="supplier.is_complete; else incompleteTemplate">

              <span style="color: green;">&#10004;</span>
            </ng-container>
            <ng-template #incompleteTemplate>
              <span style="color: red;" matTooltip="{{supplier.missing}}">&#10008;</span>
            </ng-template>
            {{ supplier.name }}</td>
          <td>{{ supplier.country }}</td>
          <td>{{ supplier.base_url }}</td>
          <td>
            <button class="btn btn-secondary" (click)="load_supplier_implementation(supplier._id)">Load Supplier
              Implementation
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
   -->
</div>
