<div class="container">
  <div class="row">
    <div class="col-5">
      <button class="btn btn-secondary" (click)="saveImplementation()">Save Implementation to database</button>
    </div>
    <div class="col-5">
      <div id="status"></div>
    </div>
  </div>


  <br><br>

  <div class="row">
    <div class="col-3">Complete: {{this.data.supplier_implementation.is_complete}}</div>
    <div class="col-6">
      <ul>
        <li *ngFor="let item of this.data.supplier_implementation.missing; let last = last">
          {{ item }}<br *ngIf="!last"/>
        </li>
      </ul>
    </div>
  </div>


  <pre>{{this.data.supplier_implementation | json}}</pre>
</div>
