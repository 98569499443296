import {Component} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AutoScraperBackendService} from "../auto-scraper-backend.service";
import {MultipleHitsSelectors, PageSource} from "../model";
import {FormControl, FormGroup} from "@angular/forms";
import {catchError} from "rxjs/operators";
import {EMPTY} from "rxjs";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-multiple-hits',
  templateUrl: './multiple-hits.component.html',
  styleUrls: ['./multiple-hits.component.css']
})

export class MultipleHitsComponent {

  constructor(private http: HttpClient, public data: AutoScraperBackendService) {
  }

  simple_search: boolean = <boolean>this.data.supplier_implementation.basic_supplier_info.simple_search;
  multi_hits_status = ""

  onSimpleSearchChange() {
    this.data.supplier_implementation.basic_supplier_info.simple_search = this.simple_search
  }

  get_page_source_from_url(idx: number, url: any) {
    const source_status = document.getElementById("source_status_mh_" + idx)
    let the_url = {"url": url.url}

    try {
      new URL(<string><unknown>url.url);
    } catch (_) {
      // @ts-ignore
      source_status.innerHTML = "No valid URL provided"
      return;
    }

    // @ts-ignore
    source_status.innerHTML = "Please wait"

    const headers = new HttpHeaders({
      'auth': this.data.token
    });

    this.http.post(environment.apiUrl + "/get_page_source", the_url, {headers})
      .pipe(
        catchError((error) => {
          // @ts-ignore
          source_status.innerHTML = "Error"
          return EMPTY
        })
      )
      .subscribe((res) => {
        const page_source = res as PageSource
        this.data.supplier_implementation.multiple_hits.search_items[idx].page_source = page_source.page_source
        this.data.supplier_implementation.multiple_hits.search_items[idx].page_source_url = url.url
        // @ts-ignore
        source_status.innerHTML = "OK"
      });
  }

  add_pasted_page_source(idx: number, page_source: any) {
    this.data.supplier_implementation.multiple_hits.search_items[idx].page_source = page_source.pasted_source
    const source_status = document.getElementById("source_status2_mh_" + idx)
    // @ts-ignore
    source_status.innerHTML = "OK"
  }

  overlay_off() {
    // @ts-ignore
    document.getElementById("overlay").style.display = "none";
  }

  multi_hit_selector_search = new FormGroup({
    page_0_url0: new FormControl(this.data.supplier_implementation.multiple_hits.search_items[0].elements[0].url),
    page_0_brand0: new FormControl(this.data.supplier_implementation.multiple_hits.search_items[0].elements[0].brand),
    page_0_number0: new FormControl(this.data.supplier_implementation.multiple_hits.search_items[0].elements[0].number),

    page_0_url1: new FormControl(this.data.supplier_implementation.multiple_hits.search_items[0].elements[1].url),
    page_0_brand1: new FormControl(this.data.supplier_implementation.multiple_hits.search_items[0].elements[1].brand),
    page_0_number1: new FormControl(this.data.supplier_implementation.multiple_hits.search_items[0].elements[1].number),

    page_1_url0: new FormControl(this.data.supplier_implementation.multiple_hits.search_items[1].elements[0].url),
    page_1_brand0: new FormControl(this.data.supplier_implementation.multiple_hits.search_items[1].elements[0].brand),
    page_1_number0: new FormControl(this.data.supplier_implementation.multiple_hits.search_items[1].elements[0].number),

    page_1_url1: new FormControl(this.data.supplier_implementation.multiple_hits.search_items[1].elements[1].url),
    page_1_brand1: new FormControl(this.data.supplier_implementation.multiple_hits.search_items[1].elements[1].brand),
    page_1_number1: new FormControl(this.data.supplier_implementation.multiple_hits.search_items[1].elements[1].number),
  });

  are_values_ok(target_value: string): boolean {
    return !(target_value === "" || target_value == null);
  }

  onSubmit_multi_hit_selector_search() {
    this.data.supplier_implementation.multiple_hits.search_items[0].elements[0].url = <string>this.multi_hit_selector_search.value.page_0_url0
    this.data.supplier_implementation.multiple_hits.search_items[0].elements[0].brand = <string>this.multi_hit_selector_search.value.page_0_brand0
    this.data.supplier_implementation.multiple_hits.search_items[0].elements[0].number = <string>this.multi_hit_selector_search.value.page_0_number0

    this.data.supplier_implementation.multiple_hits.search_items[0].elements[1].url = <string>this.multi_hit_selector_search.value.page_0_url1
    this.data.supplier_implementation.multiple_hits.search_items[0].elements[1].brand = <string>this.multi_hit_selector_search.value.page_0_brand1
    this.data.supplier_implementation.multiple_hits.search_items[0].elements[1].number = <string>this.multi_hit_selector_search.value.page_0_number1

    this.data.supplier_implementation.multiple_hits.search_items[1].elements[0].url = <string>this.multi_hit_selector_search.value.page_1_url0
    this.data.supplier_implementation.multiple_hits.search_items[1].elements[0].brand = <string>this.multi_hit_selector_search.value.page_1_brand0
    this.data.supplier_implementation.multiple_hits.search_items[1].elements[0].number = <string>this.multi_hit_selector_search.value.page_1_number0

    this.data.supplier_implementation.multiple_hits.search_items[1].elements[1].url = <string>this.multi_hit_selector_search.value.page_1_url1
    this.data.supplier_implementation.multiple_hits.search_items[1].elements[1].brand = <string>this.multi_hit_selector_search.value.page_1_brand1
    this.data.supplier_implementation.multiple_hits.search_items[1].elements[1].number = <string>this.multi_hit_selector_search.value.page_1_number1

    this.data.supplier_implementation.basic_supplier_info.simple_search = this.simple_search

    // check input values
    let to_check = [
      this.multi_hit_selector_search.value.page_0_url0,
      this.multi_hit_selector_search.value.page_0_url1,
      this.multi_hit_selector_search.value.page_1_url0,
      this.multi_hit_selector_search.value.page_1_url1
    ]

    for (let i = 0; i < to_check.length; i++) {
      if (!this.are_values_ok(<string>to_check[i])) {
        this.multi_hits_status = "Missing target value for url"
        return
      }
    }

    if (!this.are_values_ok(<string>this.data.supplier_implementation.multiple_hits.search_items[0].page_source) ||
      !this.are_values_ok(<string>this.data.supplier_implementation.multiple_hits.search_items[1].page_source)) {
      this.multi_hits_status = "Missing page source"
      return
    }

    if (this.simple_search) {

      to_check = [
        this.multi_hit_selector_search.value.page_0_brand0,
        this.multi_hit_selector_search.value.page_0_brand1,
        this.multi_hit_selector_search.value.page_1_brand0,
        this.multi_hit_selector_search.value.page_1_brand1
      ]

      for (let i = 0; i < to_check.length; i++) {
        if (!this.are_values_ok(<string>to_check[i])) {
          this.multi_hits_status = "Missing target value for brand"
          return
        }
      }

      to_check = [
        this.multi_hit_selector_search.value.page_0_number0,
        this.multi_hit_selector_search.value.page_0_number1,
        this.multi_hit_selector_search.value.page_1_number0,
        this.multi_hit_selector_search.value.page_1_number1
      ]

      for (let i = 0; i < to_check.length; i++) {
        if (!this.are_values_ok(<string>to_check[i])) {
          this.multi_hits_status = "Missing target value for number"
          return
        }
      }
    }

    this.multi_hits_status = "Working on it"
    this.find_selectors().pipe(
      catchError((error) => {
        // @ts-ignore
        this.multi_hits_status = "Error"
        return EMPTY
      })
    )
      .subscribe((res) => {
        if (typeof res === "string") {
          this.multi_hits_status = res
          return
        }
        this.data.supplier_implementation.multiple_hits_selectors = res as MultipleHitsSelectors

        if (this.data.supplier_implementation.multiple_hits_selectors.selectors.length == 0) {
          this.multi_hits_status = "No selector for container was found"
          return
        }
        if (this.data.supplier_implementation.multiple_hits_selectors.selectors[0].url.length == 0) {
          this.multi_hits_status = "No selector for url was found"
          return
        }

        if (this.simple_search) {
          if (this.data.supplier_implementation.multiple_hits_selectors.selectors[0].brand.length == 0) {
            this.multi_hits_status = "No selector for brand was found"
            return
          }

          if (this.data.supplier_implementation.multiple_hits_selectors.selectors[0].number.length == 0) {
            this.multi_hits_status = "No selector for number was found"
            return
          }
        }

        this.data.supplier_implementation.basic_supplier_info.simple_search = this.simple_search
        this.multi_hits_status = "Success"
      });


  }

  find_selectors() {
    const input = {
      "simple_search": this.simple_search,
      "search_items":
        [{
          "page_source": this.data.supplier_implementation.multiple_hits.search_items[0].page_source,
          "elements":
            [
              {
                "url": this.multi_hit_selector_search.value.page_0_url0,
                "brand": this.multi_hit_selector_search.value.page_0_brand0,
                "number": this.multi_hit_selector_search.value.page_0_number0
              },
              {
                "url": this.multi_hit_selector_search.value.page_0_url1,
                "brand": this.multi_hit_selector_search.value.page_0_brand1,
                "number": this.multi_hit_selector_search.value.page_0_number1
              }
            ]

        }
          , {
          "page_source": this.data.supplier_implementation.multiple_hits.search_items[1].page_source,
          "elements":
            [
              {
                "url": this.multi_hit_selector_search.value.page_1_url0,
                "brand": this.multi_hit_selector_search.value.page_1_brand0,
                "number": this.multi_hit_selector_search.value.page_1_number0
              },
              {
                "url": this.multi_hit_selector_search.value.page_1_url1,
                "brand": this.multi_hit_selector_search.value.page_1_brand1,
                "number": this.multi_hit_selector_search.value.page_1_number1
              }
            ]
        }
        ]
    }

    const headers = new HttpHeaders({
      'auth': this.data.token
    });

    return this.http.post(environment.apiUrl + "/search_identifier_for_multi_hit", this.data.supplier_implementation, {headers})
  }

  showSelectors(value: string) {
    let maxim: number = 15
    let selectors: string = "Top " + maxim + " selectors:\n\n";

    let overlay_text = document.getElementById("text")

    if (this.data.supplier_implementation.multiple_hits_selectors.selectors.length > 0) {
      selectors = selectors + "Selectors for container:\n"
      for (let i = 0; i < this.data.supplier_implementation.multiple_hits_selectors.selectors[0].container_selectors.length && i < 10; i++) {
        selectors = selectors + this.data.supplier_implementation.multiple_hits_selectors.selectors[0].container_selectors[i] + "\n";
      }
      selectors = selectors + "\nSelectors for " + value + ":\n"
    }

    switch (value) {
      case "url": {
        if (this.data.supplier_implementation.multiple_hits_selectors.selectors.length > 0) {
          for (let i = 0; i < this.data.supplier_implementation.multiple_hits_selectors.selectors[0].url.length && i < maxim; i++) {
            selectors = selectors + this.data.supplier_implementation.multiple_hits_selectors.selectors[0].url[i].selector + "\n";
          }
        }
        break;
      }
      case "brand": {
        if (this.data.supplier_implementation.multiple_hits_selectors.selectors.length > 0) {
          for (let i = 0; i < this.data.supplier_implementation.multiple_hits_selectors.selectors[0].brand.length && i < maxim; i++) {
            selectors = selectors + this.data.supplier_implementation.multiple_hits_selectors.selectors[0].brand[i].selector + "\n";
          }
        }
        break;
      }
      case "number": {
        if (this.data.supplier_implementation.multiple_hits_selectors.selectors.length > 0) {
          for (let i = 0; i < this.data.supplier_implementation.multiple_hits_selectors.selectors[0].number.length && i < maxim; i++) {
            selectors = selectors + this.data.supplier_implementation.multiple_hits_selectors.selectors[0].number[i].selector + "\n";
          }
        }
        break;
      }
    }
    // @ts-ignore
    overlay_text.innerText = selectors
    // @ts-ignore
    document.getElementById("overlay").style.display = "block";
  }

}
