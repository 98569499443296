import {Component} from '@angular/core';
import {AutoScraperBackendService} from "../auto-scraper-backend.service";

@Component({
  selector: 'app-meta',
  templateUrl: './meta.component.html',
  styleUrls: ['./meta.component.css']
})
export class MetaComponent {
  constructor(public data: AutoScraperBackendService) {
  }

  countries = ["AFG",  "ALA",  "ALB",  "DZA",  "ASM",  "AND",  "AGO",  "AIA",  "ATA",  "ATG",  "ARG",  "ARM",  "ABW",  "AUS",  "AUT",  "AZE",  "BHS",  "BHR",  "BGD",  "BRB",  "BLR",  "BEL",  "BLZ",  "BEN",  "BMU",  "BTN",  "BOL",  "BES",  "BIH",  "BWA",  "BVT",  "BRA",  "IOT",  "BRN",  "BGR",  "BFA",  "BDI",  "KHM",  "CMR",  "CAN",  "CPV",  "CYM",  "CAF",  "TCD",  "CHE",  "CHL",  "CHN",  "CXR",  "CCK",  "COL",  "COM",  "COG",  "COD",  "COK",  "CRI",  "CIV",  "HRV",  "CUB",  "CUW",  "CYP",  "CZE",  "DNK",  "DJI",  "DMA",  "DOM",  "ECU",  "EGY",  "SLV",  "GNQ",  "ERI",  "EST",  "ETH",  "FLK",  "FRO",  "FJI",  "FIN",  "FRA",  "GUF",  "PYF",  "ATF",  "GAB",  "GMB",  "GEO",  "DEU",  "GHA",  "GIB",  "GRC",  "GRL",  "GRD",  "GLP",  "GUM",  "GTM",  "GGY",  "GIN",  "GNB",  "GUY",  "HTI",  "HMD",  "VAT",  "HND",  "HKG", "HUN", "ISL", "IND", "IDN", "IRN", "IRQ", "IRL", "IMN", "ISR", "ITA", "JAM", "JPN", "JEY", "JOR", "KAZ", "KEN", "KIR", "PRK", "KOR", "KWT", "KGZ", "LAO", "LVA", "LBN", "LSO", "LBR", "LBY", "LIE", "LTU", "LUX", "MAC", "MKD", "MDG", "MWI", "MYS", "MDV", "MLI", "MLT", "MHL", "MTQ", "MRT", "MUS", "MYT", "MEX", "FSM", "MDA", "MCO", "MNG", "MNE", "MSR", "MAR", "MOZ", "MMR", "NAM", "NRU", "NPL", "NLD", "NCL", "NZL", "NIC", "NER", "NGA", "NIU", "NFK", "MNP", "NOR", "OMN", "PAK", "PLW", "PSE", "PAN", "PNG", "PRY", "PER", "PHL", "PCN", "POL", "PRT", "PRI", "QAT", "REU", "ROU", "RUS", "RWA", "BLM", "SHN", "KNA", "LCA", "MAF", "SPM", "VCT", "WSM", "SMR", "STP", "SAU", "SEN", "SRB", "SYC", "SLE", "SGP", "SXM", "SVK", "SVN", "SLB", "SOM", "ZAF", "SGS", "SSD", "ESP", "LKA", "SDN", "SUR", "SJM", "SWZ", "SWE", "SYR", "TWN", "TJK", "TZA", "THA", "TLS", "TGO", "TKL", "TON", "TTO", "TUN", "TUR", "TKM", "TCA", "TUV", "UGA", "UKR", "ARE", "GBR", "USA", "UMI", "URY", "UZB", "VUT", "VEN", "VNM", "VGB", "VIR", "WLF", "ESH", "YEM", "ZMB", "ZWE", "ANT", "XKX"].sort()
  currencies =  ["BGN",  "BYN",  "CHF",  "CNY",  "EUR",  "GBP",  "HKD",  "PLN",  "RUB",  "USD",  "BRL",  "SEK",  "CZK",  "HUF",  "HRK",  "DKK",  "NOK",  "RON",  "JPY",  "AUD",  "TWD",  "INR",  "ZAR",  "MYR",  "PHP",  "SGD",  "KRW",  "THB",  "NZD",  "ILS",  "TRY",  "CAD",  "IDR",  "UAH",  "ARS",  "VND",  "MXN",  "SAR",  "AED",  "AFN",  "ALL",  "DZD",  "AOA",  "XCD",  "AMD",  "AWG",  "AZN",  "BSD",  "BHD",  "BDT",  "BBD",  "BZD",  "XOF",  "BMD",  "BTN",  "BOB",  "BOV",  "BAM",  "BWP",  "BND",  "BIF",  "CVE",  "KHR",  "XAF",  "KYD",  "CLF",  "CLP",  "COP",  "COU",  "KMF",  "CDF",  "CRC",  "CUC",  "CUP",  "ANG",  "DJF",  "DOP",  "EGP",  "SVC",  "ERN",  "ETB",  "FKP",  "FJD",  "XPF",  "GMD",  "GEL",  "GHS",  "GIP",  "GTQ",  "GNF",  "GYD",  "HTG",  "HNL",  "ISK",  "XDR",  "IRR",  "IQD",  "JMD",  "JOD",  "KZT",  "KES", "KPW", "KWD", "KGS", "LAK", "LBP", "LSL", "LRD", "LYD", "MOP", "MGA", "MWK", "MVR", "MRU", "MUR", "XUA", "MXV", "MDL", "MNT", "MAD", "MZN", "MMK", "NAD", "NPR", "NIO", "NGN", "OMR", "PKR", "PAB", "PGK", "PYG", "PEN", "QAR", "MKD", "RWF", "SHP", "WST", "STN", "RSD", "SCR", "SLE", "XSU", "SBD", "SOS", "SSP", "LKR", "SDG", "SRD", "SZL", "CHE", "CHW", "SYP", "TJS", "TZS", "TOP", "TTD", "TND", "TMT", "UGX", "USN", "UYI", "UYU", "UZS", "VUV", "VEF", "VED", "YER", "ZMW", "ZWL"].sort()
  languages = ['CA', 'AK', 'KA', 'TI', 'IK', 'KN', 'NO', 'RU', 'WA', 'LO', 'CE', 'IE', 'IG', 'ID', 'MK', 'PL', 'GU', 'EL', 'NG', 'NR', 'ET', 'SA', 'KV', 'JA', 'BA', 'CS', 'SV', 'KM', 'NA', 'SC', 'HA', 'HZ', 'FI', 'AY', 'HY', 'TH', 'KJ', 'OR', 'CV', 'KO', 'UR', 'GA', 'XH', 'TO', 'HT', 'KG', 'MH', 'SO', 'FO', 'KU', 'WO', 'LT', 'JV', 'MY', 'SE', 'GL', 'BS', 'AE', 'KW', 'BO', 'BG', 'OC', 'TG', 'DA', 'FY', 'SQ', 'SW', 'CO', 'KR', 'LB', 'ZU', 'ES', 'IO', 'NN', 'HR', 'SR', 'GV', 'AV', 'CH', 'TY', 'FF', 'CR', 'DV', 'MG', 'TW', 'SI', 'ZH', 'NE', 'SD', 'AA', 'VE', 'MI', 'IU', 'FR', 'IT', 'LU', 'SN', 'CY', 'OJ', 'GD', 'MS', 'UZ', 'VO', 'UK', 'AM', 'NV', 'RM', 'HU', 'ML', 'EE', 'YO', 'EO', 'KL', 'KS', 'TE', 'EN', 'PS', 'NB', 'HE', 'LG', 'NY', 'GN', 'AZ', 'AR', 'AS', 'DE', 'IS', 'TT', 'VI', 'PI', 'ST', 'AB', 'SK', 'TR', 'TN', 'NL', 'FA', 'RN', 'SL', 'II', 'MT', 'RO', 'SG', 'SU', 'LI', 'MN', 'AN', 'HI', 'AF', 'TS', 'LN', 'OM', 'KY', 'LA', 'TA', 'QU', 'YI', 'BR', 'RW', 'BI', 'BM', 'KI', 'KK', 'TL', 'BE', 'UG', 'PA', 'BH', 'MR', 'TK', 'BN', 'LV', 'SM', 'CU', 'SS', 'PT', 'EU', 'FJ', 'ND', 'ZA', 'OS', 'HO', 'DZ', 'IA'].sort()

}

