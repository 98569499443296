import {Component} from '@angular/core';
import {AutoScraperBackendService} from "../auto-scraper-backend.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SupplierImplementation} from "../model";
import {catchError} from "rxjs/operators";
import {EMPTY} from "rxjs";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-page-type',
  templateUrl: './page-classification.component.html',
  styleUrls: ['./page-classification.component.css']
})
export class PageClassificationComponent {
  status_message: string = ""

  constructor(private http: HttpClient, public data: AutoScraperBackendService) {

  }

  do_classification_training() {
    const headers = new HttpHeaders({
      'auth': this.data.token
    });

    this.http.post(environment.apiUrl + "/page_type_classification", this.data.supplier_implementation, {headers}).pipe(
      catchError((error) => {
        this.status_message = "Error. Are all 6 page sources non-empty?"
        return EMPTY
      })
    ).subscribe((res) => {
      if (typeof res === "string") {
          this.status_message = res
          return
        }

      this.data.supplier_implementation = res as SupplierImplementation

      if (this.data.supplier_implementation.page_type_classification.method_of_page_type_classification != null) {
        this.status_message = "Success"
      }
      else {
        this.status_message = "No method of page classification found. Please check the page sources (maybe blocked?)"
      }
    });
  }
}
