import {Component} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AutoScraperBackendService} from "../auto-scraper-backend.service";
import {PageSource} from "../model";
import {catchError} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {environment} from "../../environments/environment";


@Component({
  selector: 'app-no-hits',
  templateUrl: './no-hits.component.html',
  styleUrls: ['./no-hits.component.css']
})
export class NoHitsComponent {
  constructor(private http: HttpClient, public data: AutoScraperBackendService) {
  }

  get_page_source_from_url(idx: number, url: any) {
    const source_status = document.getElementById("source_status_nh_" + idx)
    let the_url = {"url": url.url}

    try {
      new URL(<string><unknown>url.url);
    } catch (_) {// @ts-ignore
    source_status.innerHTML = "No valid URL provided"
      return;
    }

    const headers = new HttpHeaders({
      'auth': this.data.token
    });
    // @ts-ignore
    source_status.innerHTML = "Please wait"

    this.http.post(environment.apiUrl + "/get_page_source", the_url, {headers}).pipe(
      catchError((error) => {
        // @ts-ignore
        source_status.innerHTML = "Error"
        return EMPTY
      })
    )
      .subscribe((res) => {
        const page_source = res as PageSource
        this.data.supplier_implementation.no_hits[idx].page_source = page_source.page_source
        this.data.supplier_implementation.no_hits[idx].url = url.url
        // @ts-ignore
        source_status.innerHTML = "OK"
      });
  }

  add_pasted_page_source(idx: number, page_source: any) {
    this.data.supplier_implementation.no_hits[idx].page_source = page_source.pasted_source
    const source_status = document.getElementById("source_status2_nh_" + idx)
    // @ts-ignore
    source_status.innerHTML = "OK"
  }
}
