<div class="container">
  <div class="row">
    <h2>Direct Hit</h2>
    <br><br>
  </div>

  <br>

  <div class="row">
    <div style="display: flex;">
      <div style="width: 50%; background-color: lightblue;">
        <b>Direct Hit 1</b>
        <div class="row">
          <form #getPageSource_0="ngForm" (ngSubmit)="get_page_source_from_url(0, getPageSource_0.value)">
            Enter url to download:
            <input type="text" name="url" [(ngModel)]="this.data.supplier_implementation.direct_hits[0].url">
            <button class="btn btn-secondary">Get page source</button>
            <b id="source_status_dh_0"></b>
          </form>
        </div>

        <div class="row">
          <div class="col-1"></div>
          or
        </div>

        <div class="row">
          <form #addPastedPageSource_0="ngForm" (ngSubmit)="add_pasted_page_source(0, addPastedPageSource_0.value)">
            Paste page source:
            <input type="text" name="pasted_source"
                   [(ngModel)]="this.data.supplier_implementation.direct_hits[0].page_source">
            <button class="btn btn-secondary">Add page source</button>
            <b id="source_status2_dh_0"></b>
          </form>
        </div>

        <div style="float: right">
          <span>{{ this.schema_status[0] }}</span>
          <button class="btn btn-secondary" (click)="get_schema_values(0)">Try Schema</button>
        </div>

      </div>
      <div style="width: 50%; background-color: lightpink;">
        <b>Direct Hit 2</b>
        <div class="row">
          <form #getPageSource_1="ngForm" (ngSubmit)="get_page_source_from_url(1, getPageSource_1.value)">
            Enter url to download:
            <input type="text" name="url" [(ngModel)]="this.data.supplier_implementation.direct_hits[1].url">
            <button class="btn btn-secondary">Get page source</button>
            <b id="source_status_dh_1"></b>
          </form>
        </div>

        <div class="row">
          <div class="col-1"></div>
          or
        </div>

        <div class="row">
          <form #addPastedPageSource_1="ngForm" (ngSubmit)="add_pasted_page_source(1, addPastedPageSource_1.value)">
            Paste page source:
            <input type="text" name="pasted_source"
                   [(ngModel)]="this.data.supplier_implementation.direct_hits[1].page_source">
            <button class="btn btn-secondary">Add page source</button>
            <b id="source_status2_dh_1"></b>
          </form>
        </div>

        <div style="float: right">
          <span>{{ this.schema_status[1] }}</span>
          <button class="btn btn-secondary" (click)="get_schema_values(1)">Try Schema</button>
        </div>
      </div>
    </div>
  </div>

  <br>

  <div class="row">
    <div class="col-12">
      <button class="btn btn-secondary mr-auto" (click)="use_all_schema_values()">Use all schema values</button>
    </div>
  </div>

  <br>

  <!-- Table -->
  <div class="row">
    <table class="table" style="border-collapse: collapse; table-layout:fixed;">
      <thead class="thead-dark" style="font-weight: bold">
      <tr>
        <th></th>
        <th colspan="2" style="border: 1px solid black; background-color: lightblue;">Direct Hit 1</th>
        <th colspan="2" style="border: 1px solid black; background-color: lightpink;">Direct Hit 2</th>
        <th colspan="4" style="font-weight: normal">Colors of the rows: Red: must-have, yellow: should-have, grey:
          nice-to-have
          <span
            matTooltip="If the supplier does not provide some fields, it's okay to use the same element multiple times.">(?)</span>
        </th>
      </tr>
      <tr>
        <th style="border: 1px solid black;">Field</th>
        <th style="border: 1px solid black; background-color: lightblue;">Extracted Value</th>
        <th style="border: 1px solid black; background-color: lightblue;">Target Value</th>
        <th style="border: 1px solid black; background-color: lightpink;">Extracted Value</th>
        <th style="border: 1px solid black; background-color: lightpink;">Target Value</th>
        <th style="border: 1px solid black;">Use Schema?</th>
        <th style="border: 1px solid black;">Selectors</th>
        <th style="border: 1px solid black;">Go!</th>
        <th style="border: 1px solid black;">Status</th>
      </tr>
      </thead>
      <tbody>
      <tr style="background-color: rgba(255, 0, 0, 0.1)">
        <td style="border: 1px solid black;">Brand / Manufacturer</td>
        <td
          style="border: 1px solid black; background-color: lightblue;">
          {{ this.data.supplier_implementation.direct_hits[0].page_result.oem_info.brand }}
        </td>
        <td style="border: 1px solid black; background-color: lightblue;">
          <form [formGroup]="oem_brand">
            <input type="text" value="{{this.data.supplier_implementation.direct_hits[0].target_values.oem_info.brand}}"
                   formControlName="input0">
          </form>
        </td>
        <td
          style="border: 1px solid black; background-color: lightpink;">
          {{ this.data.supplier_implementation.direct_hits[1].page_result.oem_info.brand }}
        </td>
        <td style="border: 1px solid black; background-color: lightpink;">
          <form [formGroup]="oem_brand">
            <input type="text" value="{{this.data.supplier_implementation.direct_hits[1].target_values.oem_info.brand}}"
                   formControlName="input1">
          </form>
        </td>
        <td style="border: 1px solid black;"><input type="checkbox" [(ngModel)]="use_schema_oem_info_brand"
                                                    (change)="onChangeSchemaBrand()"> Use Schema
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="showSelectors('oem_brand')"
                  *ngIf="this.data.supplier_implementation.direct_hits_selectors.oem_info.brand.length > 0">Show
            Selectors
          </button>
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="onSubmit_oem_brand(true)" *ngIf="!use_schema_oem_info_brand">Go!
          </button>
          <button class="btn btn-primary" (click)="onSubmit_oem_brand(false)" *ngIf="!use_schema_oem_info_brand">Go
            (full)
          </button>
        </td>
        <td style="border: 1px solid black;">{{ oem_brand_status }}</td>
      </tr>
      <tr style="background-color: rgba(255, 0, 0, 0.1)">
        <td style="border: 1px solid black;">Manufacturer Part Number</td>
        <td
          style="border: 1px solid black; background-color: lightblue;">
          {{ this.data.supplier_implementation.direct_hits[0].page_result.oem_info.number }}
        </td>
        <td style="border: 1px solid black; background-color: lightblue;">
          <form [formGroup]="oem_number">
            <input type="text"
                   value="{{this.data.supplier_implementation.direct_hits[0].target_values.oem_info.number}}"
                   formControlName="input0">
          </form>
        </td>
        <td
          style="border: 1px solid black; background-color: lightpink;">
          {{ this.data.supplier_implementation.direct_hits[1].page_result.oem_info.number }}
        </td>
        <td style="border: 1px solid black; background-color: lightpink;">
          <form [formGroup]="oem_number">
            <input type="text"
                   value="{{this.data.supplier_implementation.direct_hits[1].target_values.oem_info.number}}"
                   formControlName="input1">
          </form>
        </td>
        <td style="border: 1px solid black;"><input type="checkbox" [(ngModel)]="use_schema_oem_info_number"
                                                    (change)="onChangeSchemaOemNumber()"> Use Schema
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="showSelectors('oem_number')"
                  *ngIf="this.data.supplier_implementation.direct_hits_selectors.oem_info.number.length > 0">Show
            Selectors
          </button>
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="onSubmit_oem_number(true)" *ngIf="!use_schema_oem_info_number">Go!
          </button>
          <button class="btn btn-primary" (click)="onSubmit_oem_number(false)" *ngIf="!use_schema_oem_info_number">Go
            (full)
          </button>
        </td>
        <td style="border: 1px solid black;">{{ oem_number_status }}</td>
      </tr>
      <tr>
        <td style="border: 1px solid black;">GTIN / EAN</td>
        <td
          style="border: 1px solid black; background-color: lightblue;">
          {{ this.data.supplier_implementation.direct_hits[0].page_result.oem_info.gtin }}
        </td>
        <td style="border: 1px solid black; background-color: lightblue;">
          <form [formGroup]="oem_gtin">
            <input type="text" value="{{this.data.supplier_implementation.direct_hits[0].target_values.oem_info.gtin}}"
                   formControlName="input0">
          </form>
        </td>
        <td
          style="border: 1px solid black; background-color: lightpink;">
          {{ this.data.supplier_implementation.direct_hits[1].page_result.oem_info.gtin }}
        </td>
        <td style="border: 1px solid black; background-color: lightpink;">
          <form [formGroup]="oem_gtin">
            <input type="text" value="{{this.data.supplier_implementation.direct_hits[1].target_values.oem_info.gtin}}"
                   formControlName="input1">
          </form>
        </td>
        <td style="border: 1px solid black;"><input type="checkbox" [(ngModel)]="use_schema_oem_info_gtin"
                                                    (change)="onChangeSchemaOemGtin()"> Use Schema
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="showSelectors('oem_gtin')"
                  *ngIf="this.data.supplier_implementation.direct_hits_selectors.oem_info.gtin.length > 0">Show
            Selectors
          </button>
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="onSubmit_oem_gtin(true)" *ngIf="!use_schema_oem_info_gtin">Go!
          </button>
          <button class="btn btn-primary" (click)="onSubmit_oem_gtin(false)" *ngIf="!use_schema_oem_info_gtin">Go (full)
          </button>
        </td>
        <td style="border: 1px solid black;">{{ oem_gtin_status }}</td>
      </tr>
      <tr style="background-color: rgba(255, 0, 0, 0.1)">
        <td style="border: 1px solid black;">Name / Title</td>
        <td
          style="border: 1px solid black; background-color: lightblue;">
          {{ this.data.supplier_implementation.direct_hits[0].page_result.internal_info.name }}
        </td>
        <td style="border: 1px solid black; background-color: lightblue;">
          <form [formGroup]="internal_name">
            <input type="text"
                   value="{{this.data.supplier_implementation.direct_hits[0].target_values.internal_info.name}}"
                   formControlName="input0">
          </form>
        </td>
        <td
          style="border: 1px solid black; background-color: lightpink;">
          {{ this.data.supplier_implementation.direct_hits[1].page_result.internal_info.name }}
        </td>
        <td style="border: 1px solid black; background-color: lightpink;">
          <form [formGroup]="internal_name">
            <input type="text"
                   value="{{this.data.supplier_implementation.direct_hits[1].target_values.internal_info.name}}"
                   formControlName="input1">
          </form>
        </td>
        <td style="border: 1px solid black;"><input type="checkbox" [(ngModel)]="use_schema_internal_info_name"
                                                    (change)="onChangeSchemaInternalName()"> Use Schema
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="showSelectors('internal_name')"
                  *ngIf="this.data.supplier_implementation.direct_hits_selectors.internal_info.name.length > 0">Show
            Selectors
          </button>
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="onSubmit_internal_name(true)"
                  *ngIf="!use_schema_internal_info_name">
            Go!
          </button>
          <button class="btn btn-primary" (click)="onSubmit_internal_name(false)"
                  *ngIf="!use_schema_internal_info_name">Go (full)
          </button>
        </td>
        <td style="border: 1px solid black;">{{ internal_name_status }}</td>
      </tr>
      <tr>
        <td style="border: 1px solid black;">Supplier Internal Number</td>
        <td
          style="border: 1px solid black; background-color: lightblue;">
          {{ this.data.supplier_implementation.direct_hits[0].page_result.internal_info.number }}
        </td>
        <td style="border: 1px solid black; background-color: lightblue;">
          <form [formGroup]="internal_number">
            <input type="text"
                   value="{{this.data.supplier_implementation.direct_hits[0].target_values.internal_info.number}}"
                   formControlName="input0">
          </form>
        </td>
        <td
          style="border: 1px solid black; background-color: lightpink;">
          {{ this.data.supplier_implementation.direct_hits[1].page_result.internal_info.number }}
        </td>
        <td style="border: 1px solid black; background-color: lightpink;">
          <form [formGroup]="internal_number">
            <input type="text"
                   value="{{this.data.supplier_implementation.direct_hits[1].target_values.internal_info.number}}"
                   formControlName="input1">
          </form>
        </td>
        <td style="border: 1px solid black;"><input type="checkbox" [(ngModel)]="use_schema_internal_info_number"
                                                    (change)="onChangeSchemaInternalNumber()"> Use Schema
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="showSelectors('internal_number')"
                  *ngIf="this.data.supplier_implementation.direct_hits_selectors.internal_info.number.length > 0">Show
            Selectors
          </button>
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="onSubmit_internal_number(true)"
                  *ngIf="!use_schema_internal_info_number">Go!
          </button>
          <button class="btn btn-primary" (click)="onSubmit_internal_number(false)"
                  *ngIf="!use_schema_internal_info_number">Go (full)
          </button>
        </td>
        <td style="border: 1px solid black;">{{ internal_number_status }}</td>
      </tr>
      <tr>
        <td style="border: 1px solid black;">Description</td>
        <td style="border: 1px solid black; background-color: lightblue;">
          {{ this.data.supplier_implementation.direct_hits[0].page_result.internal_info.description }}
        </td>
        <td style="border: 1px solid black; background-color: lightblue;">
          <form [formGroup]="internal_desc">
            <input type="text"
                   value="{{this.data.supplier_implementation.direct_hits[0].target_values.internal_info.description}}"
                   formControlName="input0">
          </form>
        </td>
        <td style="border: 1px solid black; background-color: lightpink;">
          {{ this.data.supplier_implementation.direct_hits[1].page_result.internal_info.description }}
        </td>
        <td style="border: 1px solid black; background-color: lightpink;">
          <form [formGroup]="internal_desc">
            <input type="text"
                   value="{{this.data.supplier_implementation.direct_hits[1].target_values.internal_info.description}}"
                   formControlName="input1">
          </form>
        </td>
        <td style="border: 1px solid black;"><input type="checkbox" [(ngModel)]="use_schema_internal_info_description"
                                                    (change)="onChangeSchemaInternalDescription()"> Use Schema
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="showSelectors('internal_desc')"
                  *ngIf="this.data.supplier_implementation.direct_hits_selectors.internal_info.description.length > 0">
            Show Selectors
          </button>
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="onSubmit_internal_desc(true)"
                  *ngIf="!use_schema_internal_info_description">Go!
          </button>
          <button class="btn btn-primary" (click)="onSubmit_internal_desc(false)"
                  *ngIf="!use_schema_internal_info_description">Go (full)
          </button>
        </td>
        <td style="border: 1px solid black;">{{ internal_desc_status }}</td>
      </tr>
      <tr>
        <td style="border: 1px solid black;">Product Group</td>
        <td
          style="border: 1px solid black; background-color: lightblue;">
          {{ this.data.supplier_implementation.direct_hits[0].page_result.internal_info.group }}
        </td>
        <td style="border: 1px solid black; background-color: lightblue;">
          <form [formGroup]="internal_group">
            <input type="text"
                   value="{{this.data.supplier_implementation.direct_hits[0].target_values.internal_info.group}}"
                   formControlName="input0">
          </form>
        </td>
        <td
          style="border: 1px solid black; background-color: lightpink;">
          {{ this.data.supplier_implementation.direct_hits[1].page_result.internal_info.group }}
        </td>
        <td style="border: 1px solid black; background-color: lightpink;">
          <form [formGroup]="internal_group">
            <input type="text"
                   value="{{this.data.supplier_implementation.direct_hits[1].target_values.internal_info.group}}"
                   formControlName="input1">
          </form>
        </td>
        <td style="border: 1px solid black;"><input type="checkbox" [(ngModel)]="use_schema_internal_info_group"
                                                    (change)="onChangeSchemaInternalGroup()"> Use Schema
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="showSelectors('internal_group')"
                  *ngIf="this.data.supplier_implementation.direct_hits_selectors.internal_info.group.length > 0">Show
            Selectors
          </button>
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="onSubmit_internal_group(true)"
                  *ngIf="!use_schema_internal_info_group">
            Go!
          </button>
          <button class="btn btn-primary" (click)="onSubmit_internal_group(false)"
                  *ngIf="!use_schema_internal_info_group">Go (full)
          </button>
        </td>
        <td style="border: 1px solid black;">{{ internal_group_status }}</td>
      </tr>

      <tr>
        <td style="border: 1px solid black;">Amount
          <span
            matTooltip="Here, only the extracted amount is relevant. Sonar will try to extract the actual number and divide the price by that number afterwards">(?)</span>
        </td>
        <td
          style="border: 1px solid black; background-color: lightblue;">
          {{ this.data.supplier_implementation.direct_hits[0].page_result.prices.amount }}
        </td>
        <td style="border: 1px solid black; background-color: lightblue;">
          <form [formGroup]="price_amount">
            <input type="text" value="{{this.data.supplier_implementation.direct_hits[0].target_values.prices.amount}}"
                   formControlName="input0">
          </form>
        </td>
        <td
          style="border: 1px solid black; background-color: lightpink;">
          {{ this.data.supplier_implementation.direct_hits[1].page_result.prices.amount }}
        </td>
        <td style="border: 1px solid black; background-color: lightpink;">
          <form [formGroup]="price_amount">
            <input type="text" value="{{this.data.supplier_implementation.direct_hits[1].target_values.prices.amount}}"
                   formControlName="input1">
          </form>
        </td>
        <td style="border: 1px solid black;"></td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="showSelectors('price_amount')"
                  *ngIf="this.data.supplier_implementation.direct_hits_selectors.prices.amount.length > 0">Show
            Selectors
          </button>
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="onSubmit_price_amount(true)">
            Go!
          </button>
          <button class="btn btn-primary" (click)="onSubmit_price_amount(false)">Go (full)
          </button>
        </td>
        <td style="border: 1px solid black;">{{ price_amount_status }}</td>
      </tr>

      <tr style="background-color: rgba(255, 0, 0, 0.1)">
        <td style="border: 1px solid black;">Price <span
          matTooltip="When extracting the price, a value in square brackets will be shown. This is the parsed price (text -> decimal). Please make sure this value is correct. If not, you can change the language in the Metadata (for example, if there is a comma as decimal-separator you should probably select 'DE' as language). If nothing works don't finish implementing the page.">(?)</span>
        </td>
        <td
          style="border: 1px solid black; background-color: lightblue;">
          {{ this.data.supplier_implementation.direct_hits[0].page_result.prices.price }} {{ this.parsed_price_1 }}
        </td>
        <td style="border: 1px solid black; background-color: lightblue;">
          <form [formGroup]="price_price">
            <input type="text" value="{{this.data.supplier_implementation.direct_hits[0].target_values.prices.price}}"
                   formControlName="input0">
          </form>
        </td>
        <td
          style="border: 1px solid black; background-color: lightpink;">
          {{ this.data.supplier_implementation.direct_hits[1].page_result.prices.price }} {{ this.parsed_price_2 }}
        </td>
        <td style="border: 1px solid black; background-color: lightpink;">
          <form [formGroup]="price_price">
            <input type="text" value="{{this.data.supplier_implementation.direct_hits[1].target_values.prices.price}}"
                   formControlName="input1">
          </form>
        </td>
        <td style="border: 1px solid black;"><input type="checkbox" [(ngModel)]="use_schema_prices_price"
                                                    (change)="onChangeSchemaPrice()"> Use Schema
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="showSelectors('price_price')"
                  *ngIf="this.data.supplier_implementation.direct_hits_selectors.prices.price.length > 0">Show Selectors
          </button>
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="onSubmit_price_price(true)" *ngIf="!use_schema_prices_price">Go!
          </button>
          <button class="btn btn-primary" (click)="onSubmit_price_price(false)"
                  *ngIf="!use_schema_prices_price">Go (full)
          </button>
        </td>
        <td style="border: 1px solid black;">{{ price_price_status }}</td>
      </tr>
      <tr style="background-color: rgba(255,255,0,0.1)">
        <td style="border: 1px solid black;">Delivery</td>
        <td
          style="border: 1px solid black; background-color: lightblue;">
          {{ this.data.supplier_implementation.direct_hits[0].page_result.prices.delivery_text }}
        </td>
        <td style="border: 1px solid black; background-color: lightblue;">
          <form [formGroup]="price_delivery_text">
            <input type="text"
                   value="{{this.data.supplier_implementation.direct_hits[0].target_values.prices.delivery_text}}"
                   formControlName="input0">
          </form>
        </td>
        <td
          style="border: 1px solid black; background-color: lightpink;">
          {{ this.data.supplier_implementation.direct_hits[1].page_result.prices.delivery_text }}
        </td>
        <td style="border: 1px solid black; background-color: lightpink;">
          <form [formGroup]="price_delivery_text">
            <input type="text"
                   value="{{this.data.supplier_implementation.direct_hits[1].target_values.prices.delivery_text}}"
                   formControlName="input1">
          </form>
        </td>
        <td style="border: 1px solid black;"></td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="showSelectors('price_delivery_text')"
                  *ngIf="this.data.supplier_implementation.direct_hits_selectors.prices.delivery_text.length > 0">Show
            Selectors
          </button>
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="onSubmit_price_delivery_text(true)">Go!</button>
          <button class="btn btn-primary" (click)="onSubmit_price_delivery_text(false)"
          >Go (full)
          </button>
        </td>
        <td style="border: 1px solid black;">{{ price_delivery_text_status }}</td>
      </tr>

      <tr>
        <td style="border: 1px solid black;">Condition</td>
        <td
          style="border: 1px solid black; background-color: lightblue;">
          {{ this.data.supplier_implementation.direct_hits[0].page_result.condition }}
        </td>
        <td style="border: 1px solid black; background-color: lightblue;">
          <form [formGroup]="condition">
            <input type="text"
                   value="{{this.data.supplier_implementation.direct_hits[0].target_values.condition}}"
                   formControlName="input0">
          </form>
        </td>
        <td
          style="border: 1px solid black; background-color: lightpink;">
          {{ this.data.supplier_implementation.direct_hits[1].page_result.condition }}
        </td>
        <td style="border: 1px solid black; background-color: lightpink;">
          <form [formGroup]="condition">
            <input type="text"
                   value="{{this.data.supplier_implementation.direct_hits[1].target_values.condition}}"
                   formControlName="input1">
          </form>
        </td>
        <td style="border: 1px solid black;"></td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="showSelectors('condition')"
                  *ngIf="this.data.supplier_implementation.direct_hits_selectors.condition.length > 0">Show
            Selectors
          </button>
        </td>
        <td style="border: 1px solid black;">
          <button class="btn btn-secondary" (click)="onSubmit_condition(true)">Go!</button>
          <button class="btn btn-primary" (click)="onSubmit_condition(false)"
          >Go (full)
          </button>
        </td>
        <td style="border: 1px solid black;">{{ condition_status }}</td>
      </tr>


      </tbody>
    </table>
  </div>

</div>

<!-- overlay stuff -->
<div id="overlay" (click)="overlay_off()">
  <div id="text"></div>
</div>
